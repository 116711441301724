// TeachersManagement.js

import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Card,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Select,
  MenuItem,
  Grid,
  Snackbar,
  Alert,
  Backdrop,Tooltip
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const TeachersManagement = () => {
  // State variables
  const [teachers, setTeachers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [teacherData, setTeacherData] = useState({
    id: '',
    name: '',
    subject: '',
    email: '',
    password: '',
    education: '',
    Address: '',
    Nic: '',
    Phone: '',
    Whatsapp: '',
    School: '',
    profile: null,
    existing_profile: '',
    levels: '',
    can_teach_subjects: '',
    cv: null,
    existing_cv: '',
  });

  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [viewTeacherData, setViewTeacherData] = useState({});
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [teacherToDelete, setTeacherToDelete] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const navigate = useNavigate();

  // Define the base URL for images and CVs
  const BASE_IMAGE_URL = 'https://mynewhomeschool.com/student/admin/uploads/'; // Adjust based on your server's configuration

  // Fetch all teachers on component mount
  useEffect(() => {
    fetchTeachers();
  }, []);

  // Function to fetch teachers from the backend
  const fetchTeachers = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        'https://mynewhomeschool.com/student/admin/api/teachers.php?action=fetch_teachers'
      );
      const data = await response.json();
      if (Array.isArray(data)) {
        setTeachers(data);
      } else {
        throw new Error('Invalid data format received.');
      }
    } catch (error) {
      console.error('Error fetching teachers:', error);
      setSnackbar({
        open: true,
        message: 'Failed to fetch teachers.',
        severity: 'error',
      });
    }
    setLoading(false);
  };

  // Function to open the add/edit dialog
  const handleDialogOpen = (teacher = {}) => {
    setTeacherData({
      id: teacher.id || '',
      name: teacher.name || '',
      subject: teacher.subject || '',
      email: teacher.email || '',
      password: '',
      education: teacher.education || '',
      Address: teacher.Address || '',
      Nic: teacher.Nic || '',
      Phone: teacher.Phone || '',
      Whatsapp: teacher.Whatsapp || '',
      School: teacher.School || '',
      profile: null,
      existing_profile: teacher.profile || '',
      levels: teacher.levels || '',
      can_teach_subjects: teacher.can_teach_subjects || '',
      cv: null,
      existing_cv: teacher.cv || '',
    });
    setOpenDialog(true);
  };

  // Function to close the add/edit dialog
  const handleDialogClose = () => {
    setTeacherData({
      id: '',
      name: '',
      subject: '',
      email: '',
      password: '',
      education: '',
      Address: '',
      Nic: '',
      Phone: '',
      Whatsapp: '',
      School: '',
      profile: null,
      existing_profile: '',
      levels: '',
      can_teach_subjects: '',
      cv: null,
      existing_cv: '',
    });
    setOpenDialog(false);
  };

  // Function to handle form submission for adding/updating teacher
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic form validation
    if (
      !teacherData.name ||
      !teacherData.subject ||
      !teacherData.email ||
      !teacherData.Phone
    ) {
      setSnackbar({
        open: true,
        message: 'Please fill in all required fields.',
        severity: 'warning',
      });
      return;
    }

    setLoading(true);

    const formData = new FormData();
    Object.keys(teacherData).forEach((key) => {
      if (
        (key === 'profile' || key === 'cv') &&
        !teacherData[key] &&
        teacherData[`existing_${key}`]
      ) {
        // If no new file is uploaded but existing file exists, skip appending
        return;
      }
      formData.append(key, teacherData[key]);
    });

    const action = teacherData.id ? 'update_teacher' : 'add_teacher';
    const url = `https://mynewhomeschool.com/student/admin/api/teachers.php?action=${action}`;

    try {
      const response = await fetch(url, {
        method: 'POST',
        body: formData,
      });
      const result = await response.json();
      if (result.success) {
        fetchTeachers();
        handleDialogClose();
        setSnackbar({
          open: true,
          message: result.message || 'Operation successful.',
          severity: 'success',
        });
      } else {
        throw new Error(result.message || 'Operation failed.');
      }
    } catch (error) {
      console.error('Error submitting teacher:', error);
      setSnackbar({
        open: true,
        message: error.message || 'Failed to submit teacher.',
        severity: 'error',
      });
    }
    setLoading(false);
  };

  // Function to handle teacher deletion with confirmation
  const handleDelete = (teacher) => {
    setTeacherToDelete(teacher);
    setOpenConfirmDialog(true);
  };

  // Function to confirm deletion
  const confirmDelete = async () => {
    if (!teacherToDelete) return;

    setLoading(true);
    try {
      const response = await fetch(
        `https://mynewhomeschool.com/student/admin/api/teachers.php?action=delete_teacher&id=${teacherToDelete.id}`,
        {
          method: 'GET',
        }
      );
      const result = await response.json();
      if (result.success) {
        fetchTeachers();
        setSnackbar({
          open: true,
          message: result.message || 'Teacher deleted successfully.',
          severity: 'success',
        });
      } else {
        throw new Error(result.message || 'Failed to delete teacher.');
      }
    } catch (error) {
      console.error('Error deleting teacher:', error);
      setSnackbar({
        open: true,
        message: error.message || 'Failed to delete teacher.',
        severity: 'error',
      });
    }
    setLoading(false);
    setOpenConfirmDialog(false);
    setTeacherToDelete(null);
  };

  // Function to cancel deletion
  const cancelDelete = () => {
    setOpenConfirmDialog(false);
    setTeacherToDelete(null);
  };

  // Function to handle approval status change
  const handleApprove = async (id, status) => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://mynewhomeschool.com/student/admin/api/teachers.php?action=approve_teacher&id=${id}&status=${status}`,
        {
          method: 'GET',
        }
      );
      const result = await response.json();
      if (result.success) {
        fetchTeachers();
        setSnackbar({
          open: true,
          message:
            status === 1
              ? 'Teacher approved successfully.'
              : 'Teacher rejected successfully.',
          severity: 'success',
        });
      } else {
        throw new Error(result.message || 'Failed to update approval status.');
      }
    } catch (error) {
      console.error('Error updating approval status:', error);
      setSnackbar({
        open: true,
        message: error.message || 'Failed to update approval status.',
        severity: 'error',
      });
    }
    setLoading(false);
  };

  // Function to handle approval change from Select component
  const handleApprovalChange = (id, event) => {
    const status = event.target.value;
    handleApprove(id, status);
  };

  // Function to view teacher details
  const handleViewTeacher = (teacher) => {
    setViewTeacherData(teacher);
    setOpenViewDialog(true);
  };

  // Function to close the view dialog
  const handleViewDialogClose = () => {
    setViewTeacherData({});
    setOpenViewDialog(false);
  };

  // Function to close the snackbar
  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box sx={{ padding: 3 }}>
      {/* Backdrop for Loading Spinner */}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Card sx={{ padding: 3 }}>
        <Typography variant="h4" gutterBottom>
          Teacher Management
        </Typography>

        <Button
          variant="contained"
          color="primary"
          onClick={() => handleDialogOpen()}
          sx={{ marginBottom: 3 }}
        >
          Add New Teacher
        </Button>

        {/* Teachers Table */}
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Subject</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Approval</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {teachers.map((teacher) => (
                <TableRow key={teacher.id}>
                  <TableCell>{teacher.name}</TableCell>
                  <TableCell>{teacher.subject}</TableCell>
                  <TableCell>{teacher.email}</TableCell>
                  <TableCell>{teacher.Phone}</TableCell>
                  <TableCell>
                    <Select
                      value={teacher.approve}
                      onChange={(event) =>
                        handleApprovalChange(teacher.id, event)
                      }
                      displayEmpty
                      sx={{ minWidth: 120 }}
                    >
                      <MenuItem value={1}>Approved</MenuItem>
                      <MenuItem value={0}>Rejected</MenuItem>
                      <MenuItem value="">Pending</MenuItem>
                    </Select>
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title="Edit Teacher">
                      <IconButton
                        color="primary"
                        onClick={() => handleDialogOpen(teacher)}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Teacher">
                      <IconButton
                        color="error"
                        onClick={() => handleDelete(teacher)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="View Teacher">
                      <IconButton
                        color="info"
                        onClick={() => handleViewTeacher(teacher)}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
              {teachers.length === 0 && !loading && (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No teachers found.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Dialog for Adding/Editing Teacher */}
        <Dialog
          open={openDialog}
          onClose={handleDialogClose}
          maxWidth="md"
          fullWidth
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            {teacherData.id ? 'Edit Teacher' : 'Add New Teacher'}
          </DialogTitle>
          <DialogContent>
            <Box
              component="form"
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <Grid container spacing={2}>
                {/* Name */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    label="Name"
                    value={teacherData.name}
                    onChange={(e) =>
                      setTeacherData({ ...teacherData, name: e.target.value })
                    }
                    fullWidth
                  />
                </Grid>
                {/* Subject */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    label="Subject"
                    value={teacherData.subject}
                    onChange={(e) =>
                      setTeacherData({
                        ...teacherData,
                        subject: e.target.value,
                      })
                    }
                    fullWidth
                  />
                </Grid>
                {/* Email */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    type="email"
                    label="Email"
                    value={teacherData.email}
                    onChange={(e) =>
                      setTeacherData({ ...teacherData, email: e.target.value })
                    }
                    fullWidth
                  />
                </Grid>
                {/* Password */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    type="password"
                    label="Password"
                    value={teacherData.password}
                    onChange={(e) =>
                      setTeacherData({
                        ...teacherData,
                        password: e.target.value,
                      })
                    }
                    fullWidth
                    helperText={
                      teacherData.id
                        ? 'Leave blank to keep the current password.'
                        : ''
                    }
                  />
                </Grid>
                {/* Education */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Education"
                    value={teacherData.education}
                    onChange={(e) =>
                      setTeacherData({
                        ...teacherData,
                        education: e.target.value,
                      })
                    }
                    fullWidth
                  />
                </Grid>
                {/* Address */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Address"
                    value={teacherData.Address}
                    onChange={(e) =>
                      setTeacherData({
                        ...teacherData,
                        Address: e.target.value,
                      })
                    }
                    fullWidth
                  />
                </Grid>
                {/* NIC */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="NIC"
                    value={teacherData.Nic}
                    onChange={(e) =>
                      setTeacherData({ ...teacherData, Nic: e.target.value })
                    }
                    fullWidth
                  />
                </Grid>
                {/* Phone */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    label="Phone"
                    value={teacherData.Phone}
                    onChange={(e) =>
                      setTeacherData({ ...teacherData, Phone: e.target.value })
                    }
                    fullWidth
                  />
                </Grid>
                {/* WhatsApp */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="WhatsApp"
                    value={teacherData.Whatsapp}
                    onChange={(e) =>
                      setTeacherData({
                        ...teacherData,
                        Whatsapp: e.target.value,
                      })
                    }
                    fullWidth
                  />
                </Grid>
                {/* School */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="School"
                    value={teacherData.School}
                    onChange={(e) =>
                      setTeacherData({ ...teacherData, School: e.target.value })
                    }
                    fullWidth
                  />
                </Grid>
                {/* Levels */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Levels"
                    value={teacherData.levels}
                    onChange={(e) =>
                      setTeacherData({ ...teacherData, levels: e.target.value })
                    }
                    fullWidth
                  />
                </Grid>
                {/* Can Teach Subjects */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Can Teach Subjects"
                    value={teacherData.can_teach_subjects}
                    onChange={(e) =>
                      setTeacherData({
                        ...teacherData,
                        can_teach_subjects: e.target.value,
                      })
                    }
                    fullWidth
                  />
                </Grid>
                {/* Profile Picture */}
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    Profile Picture:
                  </Typography>
                  <Button variant="contained" component="label">
                    Upload Profile
                    <input
                      type="file"
                      accept="image/*"
                      hidden
                      onChange={(e) =>
                        setTeacherData({
                          ...teacherData,
                          profile: e.target.files[0],
                        })
                      }
                    />
                  </Button>
                  {teacherData.existing_profile && (
                    <Typography variant="caption" display="block" sx={{ mt: 1 }}>
                      Current Profile: {teacherData.existing_profile}
                    </Typography>
                  )}
                </Grid>
                {/* CV */}
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    CV:
                  </Typography>
                  <Button variant="contained" component="label">
                    Upload CV
                    <input
                      type="file"
                      accept=".pdf,.doc,.docx"
                      hidden
                      onChange={(e) =>
                        setTeacherData({ ...teacherData, cv: e.target.files[0] })
                      }
                    />
                  </Button>
                  {teacherData.existing_cv && (
                    <Typography variant="caption" display="block" sx={{ mt: 1 }}>
                      Current CV: {teacherData.existing_cv}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="secondary">
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              color="primary"
              variant="contained"
              disabled={loading}
            >
              {teacherData.id ? 'Update' : 'Add'}
            </Button>
          </DialogActions>
        </Dialog>

        {/* Confirmation Dialog for Deletion */}
        <Dialog
          open={openConfirmDialog}
          onClose={cancelDelete}
          aria-labelledby="confirm-dialog-title"
          aria-describedby="confirm-dialog-description"
        >
          <DialogTitle id="confirm-dialog-title">
            Confirm Deletion
          </DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to delete{' '}
              <strong>{teacherToDelete?.name}</strong>?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={cancelDelete} color="secondary">
              Cancel
            </Button>
            <Button
              onClick={confirmDelete}
              color="error"
              variant="contained"
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        {/* Dialog for Viewing Teacher Information */}
        <Dialog
          open={openViewDialog}
          onClose={handleViewDialogClose}
          maxWidth="md"
          fullWidth
          aria-labelledby="view-dialog-title"
        >
          <DialogTitle id="view-dialog-title">Teacher Information</DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={2}>
              {/* Profile Picture */}
              {viewTeacherData.profile && (
                <Grid item xs={12} sm={4}>
                  <img
                    src={`${BASE_IMAGE_URL}${viewTeacherData.profile}`}
                    alt="Profile"
                    style={{ width: '100%', borderRadius: '8px' }}
                  />
                </Grid>
              )}
              {/* Teacher Details */}
              <Grid item xs={12} sm={viewTeacherData.profile ? 8 : 12}>
                <Typography variant="h5" gutterBottom>
                  {viewTeacherData.name}
                </Typography>
                <Typography variant="body1">
                  <strong>Subject:</strong> {viewTeacherData.subject}
                </Typography>
                <Typography variant="body1">
                  <strong>Email:</strong> {viewTeacherData.email}
                </Typography>
                <Typography variant="body1">
                  <strong>Education:</strong> {viewTeacherData.education}
                </Typography>
                <Typography variant="body1">
                  <strong>Address:</strong> {viewTeacherData.Address}
                </Typography>
                <Typography variant="body1">
                  <strong>NIC:</strong> {viewTeacherData.Nic}
                </Typography>
                <Typography variant="body1">
                  <strong>Phone:</strong> {viewTeacherData.Phone}
                </Typography>
                <Typography variant="body1">
                  <strong>WhatsApp:</strong> {viewTeacherData.Whatsapp}
                </Typography>
                <Typography variant="body1">
                  <strong>School:</strong> {viewTeacherData.School}
                </Typography>
                <Typography variant="body1">
                  <strong>Levels:</strong> {viewTeacherData.levels}
                </Typography>
                <Typography variant="body1">
                  <strong>Can Teach Subjects:</strong>{' '}
                  {viewTeacherData.can_teach_subjects}
                </Typography>
                {/* CV Download */}
                {viewTeacherData.cv && (
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="body1">
                      <strong>CV:</strong>{' '}
                      <a
                        href={`${BASE_IMAGE_URL}${viewTeacherData.cv}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Download CV
                      </a>
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleViewDialogClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Card>

      {/* Snackbar for Notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default TeachersManagement;
