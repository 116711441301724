// src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './components/Login';
import AdminPanel from './components/AdminPanel';
import Dashboard from './components/Dashboard';
import Articles from './components/Articles';
import LibraryManagement from './components/LibraryManagement';
import PaidQuestionsManagement from './components/PaidQuestionsManagement';
import QuestionsPage from './components/QuestionsPage';
import EditAnswerPage from './components/EditAnswerPage';
import EssayQuestionsPage from './components/EssayQuestionsPage';
import StudentManagementPage from './components/StudentManagementPage';
import LessonsManagementPage from './components/LessonsManagementPage';
import ArticlesEventsManagementPage from './components/ArticlesEventsManagementPage';
import PurchasesManagementPage from './components/PurchasesManagementPage';
import AdminUserManagementPage from './components/AdminUserManagementPage';
import AddPaidQuestion from './components/AddPaidQuestion';
import TeachersManagement from './components/TeachersManagement';
import TopRankedStudentsManagement from './components/TopRankedStudentsManagement';
import LevelsUnitsManagement from './components/LevelsUnitsManagement';
import AddNewEssay from './components/AddNewEssay';
import EssayQuestionsReviewPage from './components/EssayQuestionsReviewPage';
import ReviewQuestions from './components/ReviewQuestions';
import ProtectedRoute from './components/ProtectedRoute'; // Import ProtectedRoute
import NotFound from './components/NotFound'; // Create a NotFound component
import Logout from './components/logout';

function App() {
  return (
    <Router>
      <Routes>
        {/* Main Login Route */}
        <Route path="/" element={<Login />} />

        {/* Admin Panel Routes */}
        <Route
          path="/admin/*"
          element={
            <ProtectedRoute>
              <AdminPanel />
            </ProtectedRoute>
          }
        >
          {/* Nested Admin Routes */}
          <Route index element={<Dashboard />} />

          {/* Library Management */}
          <Route path="lib" element={<LibraryManagement />} />

          {/* Articles Management */}
          <Route path="articles" element={<Articles />} />

          {/* Paid Questions Management */}
          <Route path="onlinep" element={<PaidQuestionsManagement />} />

          {/* Questions page for a specific header */}
          <Route path="onlinep/questions/*" element={<QuestionsPage />} />

          {/* Other Admin Routes */}
          <Route path="essay-questions" element={<EssayQuestionsPage />} />
          <Route path="students" element={<StudentManagementPage />} />
          <Route path="lession" element={<LessonsManagementPage />} />
          <Route path="article" element={<ArticlesEventsManagementPage />} />
          <Route path="purchese" element={<PurchasesManagementPage />} />
          <Route path="adminuser" element={<AdminUserManagementPage />} />
          <Route path="addmcq" element={<AddPaidQuestion />} />
          <Route path="tea" element={<TeachersManagement />} />
          <Route path="top" element={<TopRankedStudentsManagement />} />
          <Route path="lev" element={<LevelsUnitsManagement />} />
          <Route path="addessay" element={<AddNewEssay />} />
          <Route path="essayreview" element={<EssayQuestionsReviewPage />} />
          <Route path="mcqreview" element={<ReviewQuestions />} />

          {/* Edit Answer Page (nested under /admin) */}
          <Route path="onlinep/questions/edit-answer/:answerId" element={<EditAnswerPage />} />
        </Route>

        {/* Logout Route */}
        <Route path="/logout" element={<Logout />} />

        {/* Catch-all Route for 404 */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
