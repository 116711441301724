// src/components/AdminPanel.js

import React, { useState } from 'react';
import { Outlet, Link, useLocation } from 'react-router-dom';
import {
  Drawer,
  AppBar,
  Toolbar,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  CssBaseline,
  Box,
  Avatar,
  Divider,
  IconButton,
  Tooltip,
} from '@mui/material';
import {
  Dashboard as DashboardIcon,
  Article as ArticleIcon,
  LibraryBooks as LibraryIcon,
  HelpOutline as HelpIcon,
  Logout as LogoutIcon,
  Event as EventIcon,
  School as SchoolIcon,
  QuestionAnswer as QuestionAnswerIcon,
  Class as ClassIcon,
  Menu as MenuIcon,
  People as PeopleIcon,
  Assessment as AssessmentIcon,
  Layers as LayersIcon,
  Folder as FolderIcon,
  AddBox as AddBoxIcon,
  RateReview as RateReviewIcon,
} from '@mui/icons-material';

const drawerWidth = 240;

// Sidebar Navigation Component
const Sidebar = ({ isMobileOpen, handleDrawerToggle }) => {
  const location = useLocation();

  const menuItems = [
    {
      text: 'Dashboard',
      icon: <DashboardIcon />,
      path: '/admin',
    },
    {
      text: 'Library Management',
      icon: <LibraryIcon />,
      path: '/admin/lib',
    },
    // {
    //   text: 'Articles',
    //   icon: <ArticleIcon />,
    //   path: '/admin/articles',
    // },
    // {
    //   text: 'MCQ Questions',
    //   icon: <HelpIcon />,
    //   path: '/admin/onlinep',
    // },
    // {
    //   text: 'Add MCQ Questions',
    //   icon: <AddBoxIcon />,
    //   path: '/admin/addmcq',
    // },
    // {
    //   text: 'Review MCQ Questions',
    //   icon: <RateReviewIcon />,
    //   path: '/admin/mcqreview',
    // },
    // {
    //   text: 'Essay Questions',
    //   icon: <QuestionAnswerIcon />,
    //   path: '/admin/essay-questions',
    // },
    // {
    //   text: 'Essay Review',
    //   icon: <AssessmentIcon />,
    //   path: '/admin/essayreview',
    // },
    // {
    //   text: 'Add Essay Questions',
    //   icon: <AddBoxIcon />,
    //   path: '/admin/addessay',
    // },
    // {
    //   text: 'Question Headers',
    //   icon: <LayersIcon />,
    //   path: 'https://mynewhomeschool.com/student/admin/editheader.php',
    // },
    // {
    //   text: 'Students Management',
    //   icon: <PeopleIcon />,
    //   path: '/admin/students',
    // },
    // {
    //   text: 'Top Students Management',
    //   icon: <AssessmentIcon />,
    //   path: '/admin/top',
    // },
    // {
    //   text: 'Purchases Management',
    //   icon: <FolderIcon />,
    //   path: '/admin/purchese',
    // },
    // {
    //   text: 'Doubt Management',
    //   icon: <HelpIcon />,
    //   path: 'https://mynewhomeschool.com/student/admin/doubt_mng.php',
    // },
    // {
    //   text: 'Lesson Management',
    //   icon: <ClassIcon />,
    //   path: '/admin/lession',
    // },
    // {
    //   text: 'Levels & Subjects Management',
    //   icon: <LayersIcon />,
    //   path: '/admin/lev',
    // },
    // {
    //   text: 'Events & Articles',
    //   icon: <EventIcon />,
    //   path: '/admin/article',
    // },
    // {
    //   text: 'Admin Users',
    //   icon: <PeopleIcon />,
    //   path: '/admin/adminuser',
    // },
    // {
    //   text: 'Teachers',
    //   icon: <PeopleIcon />,
    //   path: '/admin/tea',
    // },
    {
      text: 'Logout',
      icon: <LogoutIcon />,
      path: '/logout',
    },
  ];

  const drawerContent = (
    <Box sx={{ overflow: 'auto' }}>
      {/* Header */}
      <Toolbar sx={{ backgroundColor: '#1abc9c', color: 'white', display: 'flex', alignItems: 'center' }}>
        <Avatar sx={{ bgcolor: '#2c3e50', mr: 2 }}>A</Avatar>
        <Typography variant="h6" noWrap>
          Admin Panel
        </Typography>
      </Toolbar>

      <Divider />

      {/* Navigation Items */}
      <List>
        {menuItems.map((item) => (
          <Tooltip key={item.text} title={item.text} placement="right" arrow>
            <ListItemButton
              component={item.path.startsWith('http') ? 'a' : Link}
              to={item.path.startsWith('http') ? undefined : item.path}
              href={item.path.startsWith('http') ? item.path : undefined}
              target={item.path.startsWith('http') ? '_blank' : '_self'}
              selected={location.pathname === item.path}
              sx={{
                '&.Mui-selected': {
                  backgroundColor: '#ecf0f1',
                  '& .MuiListItemIcon-root': {
                    color: '#1abc9c',
                  },
                },
                '&:hover': {
                  backgroundColor: '#f5f5f5',
                },
              }}
            >
              <ListItemIcon sx={{ color: '#1abc9c' }}>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          </Tooltip>
        ))}
      </List>
    </Box>
  );

  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="navigation links"
    >
      {/* Temporary Drawer for Mobile */}
      <Drawer
        variant="temporary"
        open={isMobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            bgcolor: 'white',
            color: '#2c3e50',
          },
        }}
      >
        {drawerContent}
      </Drawer>

      {/* Permanent Drawer for Desktop */}
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            bgcolor: 'white',
            color: '#2c3e50',
          },
        }}
        open
      >
        {drawerContent}
      </Drawer>
    </Box>
  );
};

// Admin Panel Layout Component
const AdminPanel = () => {
  const [isMobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!isMobileOpen);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      {/* AppBar for the top bar */}
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: '#1abc9c',
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }} // Show only on mobile
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Admin Dashboard
          </Typography>
          {/* Example: User Avatar */}
          <Box sx={{ flexGrow: 1 }} />
          <Avatar alt="Admin" src="/static/images/avatar/1.jpg" />
        </Toolbar>
      </AppBar>

      {/* Sidebar Drawer */}
      <Sidebar isMobileOpen={isMobileOpen} handleDrawerToggle={handleDrawerToggle} />

      {/* Main Content Area */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          marginTop: '64px', // Height of AppBar
          backgroundColor: '#f4f6f8',
          minHeight: '100vh',
        }}
      >
        {/* Outlet will render the nested routes content */}
        <Outlet />
      </Box>
    </Box>
  );
};

export default AdminPanel;
