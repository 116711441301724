import React, { useEffect, useState } from 'react';
import {
  Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Select, MenuItem, FormControl, InputLabel, Button, CircularProgress, Pagination, TextField
} from '@mui/material';
import { Save as SaveIcon } from '@mui/icons-material';

const PurchasesManagementPage = () => {
  const [purchases, setPurchases] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1); // Pagination state
  const [totalPages, setTotalPages] = useState(1); // Total pages for pagination
  const [searchTerm, setSearchTerm] = useState(''); // Search term state

  const limit = 10; // Limit per page

  // Fetch purchases with pagination and search
  const fetchPurchases = (page = 1, search = '') => {
    setLoading(true);
    fetch(`https://mynewhomeschool.com/student/admin/api/fetch_purchases.php?page=${page}&limit=${limit}&search=${search}`)
      .then(response => response.json())
      .then(data => {
        setPurchases(data.purchases);
        setTotalPages(data.pages);
        setLoading(false);
      })
      .catch(err => {
        setError(err.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchPurchases(page, searchTerm);
  }, [page, searchTerm]);

  // Handle Payment Status Change
  const handlePaymentStatusChange = (purchaseId, newStatus) => {
    setPurchases(purchases.map(purchase => (
      purchase.id === purchaseId ? { ...purchase, payment_status: newStatus } : purchase
    )));
  };

  // Update Payment Status
  const updatePaymentStatus = (purchaseId, newStatus) => {
    fetch('https://mynewhomeschool.com/student/admin/api/update_payment_status.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: purchaseId,
        payment_status: newStatus
      })
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          alert('Payment status updated successfully.');
        } else {
          alert('Error updating payment status.');
        }
      })
      .catch(err => {
        console.error('Error updating payment status:', err);
        alert('Error updating payment status.');
      });
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Purchases Management
      </Typography>

      {/* Search Input */}
      <Box sx={{ mb: 2 }}>
        <TextField
          label="Search by Student Name or Purchase Number"
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Purchase Number</TableCell>
              <TableCell>Student Name</TableCell>
              <TableCell>Level ID</TableCell>
              <TableCell>Unit ID</TableCell>
              <TableCell>Total Price</TableCell>
              <TableCell>Purchase Date</TableCell>
              <TableCell>Payment Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {purchases.map((purchase) => (
              <TableRow key={purchase.id}>
                <TableCell>{purchase.purchase_number}</TableCell>
                <TableCell>{purchase.student_name}</TableCell> {/* Display student's name */}
                <TableCell>{purchase.level_id}</TableCell>
                <TableCell>{purchase.unit_id}</TableCell>
                <TableCell>LKR {purchase.total_price}</TableCell>
                <TableCell>{new Date(purchase.purchase_date).toLocaleDateString()}</TableCell>
                <TableCell>
                  <FormControl fullWidth>
                    <InputLabel>Payment Status</InputLabel>
                    <Select
                      value={purchase.payment_status}
                      onChange={(e) => handlePaymentStatusChange(purchase.id, e.target.value)}
                    >
                      <MenuItem value="Pending">Pending</MenuItem>
                      <MenuItem value="paid">Paid</MenuItem>
                      <MenuItem value="Failed">Failed</MenuItem>
                    </Select>
                  </FormControl>
                  {purchase.payment_status}
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<SaveIcon />}
                    onClick={() => updatePaymentStatus(purchase.id, purchase.payment_status)}
                  >
                    Save
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={(e, value) => setPage(value)}
        />
      </Box>
    </Box>
  );
};

export default PurchasesManagementPage;
