// src/components/Login.js

import React, { useState, useContext } from 'react';
import {
  TextField,
  Button,
  Card,
  Typography,
  Box,
  Alert,
  Snackbar,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../contexts/AuthContext'; // We'll create this context
import { styled } from '@mui/system';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const [loading, setLoading] = useState(false);
  
  const navigate = useNavigate();
  const { login } = useContext(AuthContext); // Using AuthContext to handle login

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage('');

    try {
      const response = await fetch('https://mynewhomeschool.com/student/admin/api/login.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          username,
          password,
        }),
      });

      const data = await response.json();

      if (data.status === 'success') {
        if (data.role === 'admin') {
          // Store the user_id, role, and permissions in localStorage
          const authData = {
            user_id: data.user_id,
            role: data.role,
            permissions: data.sub1,
          };
          localStorage.setItem('authData', JSON.stringify(authData));

          // Update AuthContext
          login(authData);

          // Navigate to the admin dashboard
          navigate('/admin');
        } else {
          // Show an error message if the role is not admin
          setErrorMessage('Access denied. Only admins can log in.');
          setSnackbar({
            open: true,
            message: 'Access denied. Only admins can log in.',
            severity: 'error',
          });
        }
      } else {
        // Show the server-provided error message
        setErrorMessage(data.message);
        setSnackbar({
          open: true,
          message: data.message,
          severity: 'error',
        });
      }
    } catch (error) {
      // Show a connection error message
      setErrorMessage('Error connecting to server');
      setSnackbar({
        open: true,
        message: 'Error connecting to server',
        severity: 'error',
      });
    }

    setLoading(false);
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      bgcolor="#f0f2f5"
    >
      <Card sx={{ padding: 4, width: '100%', maxWidth: 400, boxShadow: 3 }}>
        <Typography variant="h4" component="h1" gutterBottom align="center">
          Admin Login
        </Typography>
        {errorMessage && (
          <Alert severity="error" sx={{ marginBottom: 2 }}>
            {errorMessage}
          </Alert>
        )}
        <form onSubmit={handleLogin}>
          <TextField
            label="Username"
            variant="outlined"
            fullWidth
            margin="normal"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <TextField
            label="Password"
            variant="outlined"
            type="password"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ marginTop: 2 }}
            disabled={loading}
          >
            {loading ? 'Logging in...' : 'Login'}
          </Button>
        </form>
      </Card>

      {/* Snackbar for Notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      {/* Backdrop for Loading Spinner */}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default Login;
