import React, { useState, useEffect } from 'react';
import {
  Box, Typography, Card, CardContent, IconButton, Pagination, Paper, InputBase, Button,
  Dialog, DialogTitle, DialogContent, TextField, DialogActions, CircularProgress, MenuItem,
  List, ListItemText, ListItemButton, Collapse, Backdrop,FormControl,
  InputLabel,
  Select,
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, ExpandLess, ExpandMore } from '@mui/icons-material';

const LessonsManagementPage = () => {
  const [lessons, setLessons] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredLessons, setFilteredLessons] = useState([]);
  const [page, setPage] = useState(1);
  const [lessonModalOpen, setLessonModalOpen] = useState(false);
  const [lessonToEdit, setLessonToEdit] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [lessonToDelete, setLessonToDelete] = useState(null);
  const [levels, setLevels] = useState([]);
  const [units, setUnits] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [unitTypes, setUnitTypes] = useState([]);
  const [formError, setFormError] = useState(null);
  const [selectedLevelId, setSelectedLevelId] = useState(null);
  const [selectedUnitId, setSelectedUnitId] = useState(null);
  const [openLevels, setOpenLevels] = useState({});
  const [levelModalOpen, setLevelModalOpen] = useState(false);
  const [unitModalOpen, setUnitModalOpen] = useState(false);
  const itemsPerPage = 10;

  // Fetch lessons, levels, units, and teachers
  useEffect(() => {
    fetchLevelsUnitsTeachers();
  }, []);

  useEffect(() => {
    fetchLessons();
  }, [selectedLevelId, selectedUnitId, searchTerm]);

  const fetchLessons = () => {
    setLoading(true);
    let url = `https://mynewhomeschool.com/student/admin/api/lesson_handler.php?action=fetch&search=${searchTerm}`;
    if (selectedLevelId) {
      url += `&level_id=${selectedLevelId}`;
    }
    if (selectedUnitId) {
      url += `&unit_id=${selectedUnitId}`;
    }
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setLessons(data.lessons || []);
      })
      .catch((error) => console.error("Error fetching lessons:", error))
      .finally(() => setLoading(false));
  };

  const fetchLevelsUnitsTeachers = () => {
    setLoading(true);
    fetch(`https://mynewhomeschool.com/student/admin/api/lesson_handler.php?action=fetch_levels_units_teachers`)
      .then((res) => res.json())
      .then((data) => {
        setLevels(data.levels || []);
        setUnits(data.units || []);
        setTeachers(data.teachers || []);

        // Extract unique unit types
        const uniqueUnitTypes = [...new Set(data.units.map(unit => unit.unit_type).filter(Boolean))];
        setUnitTypes(uniqueUnitTypes);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => setLoading(false));
  };

  // Filter lessons based on search term
  useEffect(() => {
    const filtered = lessons.filter(lesson =>
      lesson.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      lesson.description.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredLessons(filtered);
  }, [searchTerm, lessons]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // Lesson Handlers
  const handleAddLesson = () => {
    setLessonToEdit(null);
    setLessonModalOpen(true);
    setFormError(null);
  };

  const handleEditLesson = (lesson) => {
    setLessonToEdit(lesson);
    setLessonModalOpen(true);
    setFormError(null);
  };

  const handleDeleteLesson = (lesson) => {
    setLessonToDelete(lesson);
    setDeleteConfirmOpen(true);
  };

  const confirmDeleteLesson = () => {
    setDeleteConfirmOpen(false);
    setLoading(true);
    fetch('https://mynewhomeschool.com/student/admin/api/lesson_handler.php?action=delete', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: lessonToDelete.id }),
    })
      .then(() => {
        fetchLessons();
        setLessonToDelete(null);
      })
      .catch((error) => {
        console.error("Error deleting lesson:", error);
        setLoading(false);
      });
  };

  const handleLessonSubmit = (formData) => {
    // Close the modal before starting loading
    setLessonModalOpen(false);
    setLoading(true);
    const url = lessonToEdit
      ? 'https://mynewhomeschool.com/student/admin/api/lesson_handler.php?action=update'
      : 'https://mynewhomeschool.com/student/admin/api/lesson_handler.php?action=add';
  
    const formDataToSend = new FormData();
  
    // Ensure the lesson ID is added if editing
    if (lessonToEdit && lessonToEdit.id) {
      formDataToSend.append('id', lessonToEdit.id); // Add the lesson ID
    }
  
    // Remove 'id' from formData if it exists
    if ('id' in formData) {
      delete formData.id;
    }
  
    // Loop through the form data and append fields to FormData
    for (const key in formData) {
      if (formData[key] !== null) {
        formDataToSend.append(key, formData[key]); // Append all non-null form fields
      }
    }
  
    // Fetch the URL to add or update the lesson
    fetch(url, {
      method: 'POST',
      body: formDataToSend, // Send form data as multipart/form-data
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          setFormError(data.error); // Handle any error returned by the server
          setLoading(false);
        } else {
          fetchLessons(); // Refresh the lessons list
          setFormError(null); // Clear any previous errors
        }
      })
      .catch((error) => {
        console.error("Error saving lesson:", error); // Log any errors
        setFormError('An error occurred while saving the lesson.'); // Display a general error message
        setLoading(false);
      });
  };
  

  // Level Handlers
  const handleAddLevel = () => {
    setLevelModalOpen(true);
  };

  const handleLevelSubmit = (formData) => {
    setLevelModalOpen(false);
    setLoading(true);
    fetch('https://mynewhomeschool.com/student/admin/api/lesson_handler.php?action=add_level', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          alert(data.error);
          setLoading(false);
        } else {
          fetchLevelsUnitsTeachers();
        }
      })
      .catch((error) => {
        console.error("Error adding level:", error);
        alert('An error occurred while adding the level.');
        setLoading(false);
      });
  };

  // Unit Handlers
  const handleAddUnit = (levelId) => {
    setSelectedLevelId(levelId);
    setUnitModalOpen(true);
  };

  const handleUnitSubmit = (formData) => {
    setUnitModalOpen(false);
    setLoading(true);
    fetch('https://mynewhomeschool.com/student/admin/api/lesson_handler.php?action=add_unit', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          alert(data.error);
          setLoading(false);
        } else {
          fetchLevelsUnitsTeachers();
        }
      })
      .catch((error) => {
        console.error("Error adding unit:", error);
        alert('An error occurred while adding the unit.');
        setLoading(false);
      });
  };

  // Sidebar Handlers
  const handleLevelClick = (levelId) => {
    setSelectedLevelId(levelId);
    setSelectedUnitId(null);
    setOpenLevels(prev => ({ ...prev, [levelId]: !prev[levelId] }));
  };

  const handleUnitClick = (unitId) => {
    setSelectedUnitId(unitId);
  };

  const LevelModal = ({ open, onClose, onSubmit }) => {
    const [formData, setFormData] = useState({
      title: '',
      featured_image: '',
    });

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = () => {
      onSubmit(formData);
    };

    return (
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>Add Level</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            fullWidth
            required
          />
          <TextField
            margin="dense"
            label="Featured Image URL"
            name="featured_image"
            value={formData.featured_image}
            onChange={handleChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">Cancel</Button>
          <Button onClick={handleSubmit} color="primary">Add</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const UnitModal = ({ open, onClose, onSubmit, levelId }) => {
    const [formData, setFormData] = useState({
      title: '',
      level_id: levelId || '',
      price: '',
      unit_type: '',
      related_subject: '',
    });

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = () => {
      onSubmit(formData);
    };

    return (
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>Add Subject</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            fullWidth
            required
          />
          <TextField
            select
            margin="dense"
            label="Level"
            name="level_id"
            value={formData.level_id}
            onChange={handleChange}
            fullWidth
            required
          >
            {levels.map((level) => (
              <MenuItem key={level.id} value={level.id}>
                {level.title}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            margin="dense"
            label="Price"
            name="price"
            value={formData.price}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            select
            margin="dense"
            label="Unit Type (Subject)"
            name="unit_type"
            value={formData.unit_type}
            onChange={handleChange}
            fullWidth
            required
          >
            {unitTypes.map((type, index) => (
              <MenuItem key={index} value={type}>
                {type}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            margin="dense"
            label="Related Subject (Optional)"
            name="related_subject"
            value={formData.related_subject}
            onChange={handleChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">Cancel</Button>
          <Button onClick={handleSubmit} color="primary">Add</Button>
        </DialogActions>
      </Dialog>
    );
  };

  // Lesson Modal Component
  const LessonModal = ({ open, onClose, onSubmit, lesson, formError }) => {
    const [formData, setFormData] = useState({
      title: lesson?.title || '',
      level_id: lesson?.level_id || '',
      unit_id: lesson?.unit_id || '',
      description: lesson?.description || '',
      instructions: lesson?.instructions || '',
      video_url: lesson?.video_url || '',
      image: null, // Image file for upload
      pdf_materials: null,
      powerpoint: null,
      assignments: null,
      unitno: lesson?.unitno || '',
      type: lesson?.type || '',
      Teacher: lesson?.Teacher || '',
    });

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
      const { name, files } = e.target;
      setFormData({ ...formData, [name]: files[0] });
    };

    useEffect(() => {
      if (formData.unit_id) {
        const selectedUnit = units.find(unit => unit.id === formData.unit_id);
        if (selectedUnit) {
          setFormData(prevData => ({
            ...prevData,
            type: selectedUnit.unit_type || '',
          }));
        }
      }
    }, [formData.unit_id]);

    const handleSubmit = () => {
      onSubmit(formData);
    };

    return (
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>{lesson ? 'Edit Lesson' : 'Add Lesson'}</DialogTitle>
        <DialogContent>
          {formError && (
            <Typography color="error" variant="body2">
              {formError}
            </Typography>
          )}
          <TextField
            margin="dense"
            label="Title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            fullWidth
            required
          />
          <TextField
            select
            margin="dense"
            label="Level"
            name="level_id"
            value={formData.level_id}
            onChange={handleChange}
            fullWidth
            required
          >
            {levels.map((level) => (
              <MenuItem key={level.id} value={level.id}>
                {level.title}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            margin="dense"
            label="Subject"
            name="unit_id"
            value={formData.unit_id}
            onChange={handleChange}
            fullWidth
            required
          >
            {units.filter(unit => unit.level_id === formData.level_id).map((unit) => (
              <MenuItem key={unit.id} value={unit.id}>
                {unit.title}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            margin="dense"
            label="Type"
            name="type"
            value={formData.type}
            onChange={handleChange}
            fullWidth
            required
          >
            {unitTypes.map((type, index) => (
              <MenuItem key={index} value={type}>
                {type}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            margin="dense"
            label="Description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            fullWidth
            multiline
            rows={4}
            required
          />
          <TextField
            margin="dense"
            label="Instructions"
            name="instructions"
            value={formData.instructions}
            onChange={handleChange}
            fullWidth
            multiline
            rows={4}
          />
          <TextField
            margin="dense"
            label="Video URL"
            name="video_url"
            value={formData.video_url}
            onChange={handleChange}
            fullWidth
          />

          {/* Image upload */}
          <input
            type="file"
            name="image"
            onChange={handleFileChange}
            accept="image/*"
            style={{ marginTop: '10px' }}
          />
          <Typography variant="body2">Upload Lesson Image</Typography>

          {/* File upload fields */}
          <input
            type="file"
            name="pdf_materials"
            onChange={handleFileChange}
            accept="application/pdf"
            style={{ marginTop: '10px' }}
          />
          <Typography variant="body2">Upload PDF Materials</Typography>

          <input
            type="file"
            name="powerpoint"
            onChange={handleFileChange}
            accept=".ppt,.pptx"
            style={{ marginTop: '10px' }}
          />
          <Typography variant="body2">Upload PowerPoint Materials</Typography>

          <input
            type="file"
            name="assignments"
            onChange={handleFileChange}
            accept=".doc,.docx,.pdf"
            style={{ marginTop: '10px' }}
          />
          <Typography variant="body2">Upload Assignments</Typography>

          <FormControl fullWidth margin="dense">
            <InputLabel>Month of the lession</InputLabel>
            <Select
              label="Month"
              name="unitno"
              value={formData.unitno}
              onChange={handleChange}
            >
              {Array.from({ length: 12 }, (_, index) => (
                <MenuItem key={index + 1} value={index + 1}>
                  {index + 1}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            select
            margin="dense"
            label="Teacher"
            name="Teacher"
            value={formData.Teacher}
            onChange={handleChange}
            fullWidth
            required
          >
            {teachers.map((teacher) => (
              <MenuItem key={teacher.id} value={teacher.name}>
                {teacher.name}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">Cancel</Button>
          <Button onClick={handleSubmit} color="primary">{lesson ? 'Update' : 'Add'}</Button>
        </DialogActions>
      </Dialog>
    );
  };

  // Lesson Card Component
  const LessonCard = ({ lesson }) => (
    <Card sx={{ mb: 3, boxShadow: 3, borderRadius: 2 }}>
      <CardContent>
        {/* Display the lesson image if available */}
        {lesson.image && (
          <Box sx={{ mb: 2, display: 'flex', justifyContent: 'center' }}>
            <img 
              src={`https://mynewhomeschool.com/student/admin/${lesson.image}`}
              alt={lesson.title} 
              style={{ maxWidth: '100%', maxHeight: '200px', borderRadius: '8px', objectFit: 'cover' }} 
            />
          </Box>
        )}

        <Typography variant="h5" sx={{ fontWeight: 'bold', textAlign: 'center', mb: 1 }}>
          {lesson.title}
        </Typography>
        
        <Typography variant="body1" sx={{ color: 'gray', mb: 1 }}>
          <strong>Level:</strong> {lesson.level_title || 'N/A'}
        </Typography>
        
        <Typography variant="body1" sx={{ color: 'gray', mb: 1 }}>
          <strong>Unit:</strong> {lesson.unit_title || 'N/A'}
        </Typography>
        
        <Typography variant="body1" sx={{ color: 'gray', mb: 1 }}>
          <strong>Type:</strong> {lesson.type || 'N/A'}
        </Typography>

        <Typography variant="body1" sx={{ color: 'gray', mb: 1 }}>
          <strong>Teacher:</strong> {lesson.Teacher}
        </Typography>

        <Typography variant="body2" sx={{ mt: 2, mb: 3 }}>
          {lesson.description}
        </Typography>

        {lesson.video_url && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => window.open(lesson.video_url, '_blank')}
            sx={{ display: 'block', margin: '0 auto', mb: 2 }}
          >
            Watch Video
          </Button>
        )}
      </CardContent>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 1, borderTop: '1px solid #f0f0f0' }}>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<EditIcon />}
          onClick={() => handleEditLesson(lesson)}
        >
          Edit
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<DeleteIcon />}
          onClick={() => handleDeleteLesson(lesson)}
        >
          Delete
        </Button>
      </Box>
    </Card>
  );

  return (
    <Box sx={{ display: 'flex', p: 2 }}>
      {/* Backdrop for Loading Spinner */}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* Sidebar */}
      <Box sx={{ width: 250, mr: 2 }}>
        <Typography variant="h6">Filter by Level and Unit</Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddLevel}
          sx={{ my: 1 }}
        >
          Add Level
        </Button>
        <List component="nav">
          {levels.map((level) => (
            <React.Fragment key={level.id}>
              <ListItemButton onClick={() => handleLevelClick(level.id)}>
                <ListItemText primary={level.title} />
                {openLevels[level.id] ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={openLevels[level.id]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => handleAddUnit(level.id)}
                  >
                    <AddIcon fontSize="small" /> <Typography variant="body2">Add Unit</Typography>
                  </ListItemButton>
                  {units.filter(unit => unit.level_id === level.id).map((unit) => (
                    <ListItemButton
                      key={unit.id}
                      sx={{ pl: 4 }}
                      onClick={() => handleUnitClick(unit.id)}
                      selected={selectedUnitId === unit.id}
                    >
                      <ListItemText primary={unit.title} />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            </React.Fragment>
          ))}
        </List>
      </Box>

      {/* Main Content */}
      <Box sx={{ flex: 1 }}>
        <Typography variant="h5" sx={{ mb: 2 }}>Lessons Management</Typography>

        <Paper component="form" sx={{ mb: 2, p: '2px 4px', display: 'flex', alignItems: 'center' }}>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search lessons"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Paper>

        <Button onClick={handleAddLesson} variant="contained" color="primary" startIcon={<AddIcon />}>
          Add Lesson
        </Button>

        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            {/* The Backdrop component already shows the loading spinner */}
          </Box>
        ) : filteredLessons.length > 0 ? (
          filteredLessons.slice((page - 1) * itemsPerPage, page * itemsPerPage).map((lesson) => (
            <LessonCard
              key={lesson.id}
              lesson={lesson}
            />
          ))
        ) : (
          <Typography>No lessons found</Typography>
        )}

        <Pagination
          count={Math.ceil(filteredLessons.length / itemsPerPage)}
          page={page}
          onChange={handlePageChange}
          sx={{ mt: 2 }}
        />
      </Box>

      {/* Modals */}
      <LessonModal
        open={lessonModalOpen}
        onClose={() => { setLessonModalOpen(false); setFormError(null); }}
        onSubmit={handleLessonSubmit}
        lesson={lessonToEdit}
        formError={formError}
      />

      <LevelModal
        open={levelModalOpen}
        onClose={() => setLevelModalOpen(false)}
        onSubmit={handleLevelSubmit}
      />

      <UnitModal
        open={unitModalOpen}
        onClose={() => setUnitModalOpen(false)}
        onSubmit={handleUnitSubmit}
        levelId={selectedLevelId}
      />

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this lesson?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={confirmDeleteLesson} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default LessonsManagementPage;
