import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  IconButton,
  Pagination,
  Paper,
  InputBase,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import he from 'he'; // Import the 'he' library for decoding HTML entities
import DOMPurify from 'dompurify'; // Import DOMPurify for sanitizing HTML

// Question Card Component
const QuestionCard = ({
  question,
  handleEditAnswer,
  handleDeleteAnswer,
  handleEditQuestion,
  handleDeleteQuestion,
  handleAddAnswer
}) => {
  // Function to decode and sanitize HTML entities
  const decodeAndSanitize = (html) => {
    const decoded = he.decode(html);
    return DOMPurify.sanitize(decoded);
  };

  return (
    <Card sx={{ mb: 2, p: 2 }}>
      <CardContent>
        {/* Render Question Header */}
        <Typography
          variant="h6"
          dangerouslySetInnerHTML={{
            __html: decodeAndSanitize(question.question_header) || 'No header provided',
          }}
        />

        {/* Render Question Text */}
        <Typography
          variant="body1"
          dangerouslySetInnerHTML={{
            __html: decodeAndSanitize(question.question_text) || 'No question text provided',
          }}
        />

        {/* Render Question Image if available */}
        {question.question_image && (
          <img
            src={`https://mynewhomeschool.com/student/admin/${question.question_image}`}
            alt="Question"
            style={{ width: '150px', marginTop: '10px' }}
            loading="lazy" // Lazy loading for performance
          />
        )}

        {/* Render Additional Info */}
        <Typography
          variant="body1"
          dangerouslySetInnerHTML={{
            __html: decodeAndSanitize(question.additional_info) || 'No additional info provided',
          }}
        />

        {/* Render Additional Info Image if available */}
        {question.additional_info_image && (
          <img
            src={`https://mynewhomeschool.com/student/admin/${question.additional_info_image}`}
            alt="Additional Info"
            style={{ width: '150px', marginTop: '10px' }}
            loading="lazy" // Lazy loading for performance
          />
        )}
      </CardContent>

      {/* Answers Section */}
      <Box>
        <Typography variant="h6" sx={{ mt: 2 }}>
          Answers:
        </Typography>
        {question.answers && question.answers.length > 0 ? (
          question.answers.map((answer) => (
            <Card key={answer.id} sx={{ display: 'flex', mb: 1, p: 1 }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                {/* Render Answer Text */}
                <Typography
                  variant="body1"
                  dangerouslySetInnerHTML={{
                    __html: decodeAndSanitize(answer.answer_text) || 'No answer text provided',
                  }}
                />

                {/* Render Answer Image if available */}
                {answer.answer_image && (
                  <img
                    src={`https://mynewhomeschool.com/student/admin/${answer.answer_image}`}
                    alt="Answer"
                    style={{ width: '100px', marginTop: '10px' }}
                    loading="lazy" // Lazy loading for performance
                  />
                )}

                {/* Indicate if the Answer is Correct */}
                <Typography variant="body2">
                  {Number(answer.is_correct) === 1 ? 'Correct Answer' : 'Incorrect Answer'}
                </Typography>
              </Box>
              <Box>
                {/* Edit Answer Button */}
                <IconButton
                  color="primary"
                  onClick={() => handleEditAnswer(question.id, answer)}
                  aria-label="edit answer"
                >
                  <EditIcon />
                </IconButton>

                {/* Delete Answer Button */}
                <IconButton
                  color="secondary"
                  onClick={() => handleDeleteAnswer(question.id, answer.id)}
                  aria-label="delete answer"
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Card>
          ))
        ) : (
          <Typography>No answers available</Typography>
        )}
      </Box>

      {/* Action Buttons */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
        {/* Edit Question Button */}
        <IconButton
          color="primary"
          onClick={() => handleEditQuestion(question)}
          aria-label="edit question"
        >
          <EditIcon />
        </IconButton>

        {/* Delete Question Button */}
        <IconButton
          color="secondary"
          onClick={() => handleDeleteQuestion(question.id)}
          aria-label="delete question"
        >
          <DeleteIcon />
        </IconButton>

        {/* Add Answer Button */}
        <Button
          onClick={() => handleAddAnswer(question.id)}
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
        >
          Add Answer
        </Button>
      </Box>
    </Card>
  );
};

const QuestionsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Extract and decode the header from the URL
  const rawHeader = location.pathname.replace('/onlinep/questions/', '').replace('/admin', '');
  const decodedHeader = decodeURIComponent(rawHeader.trim());

  const [questions, setQuestions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [page, setPage] = useState(1);
  const [questionModalOpen, setQuestionModalOpen] = useState(false);
  const [questionToEdit, setQuestionToEdit] = useState(null);
  const [answerModalOpen, setAnswerModalOpen] = useState(false);
  const [answerToEdit, setAnswerToEdit] = useState(null);
  const [currentQuestionId, setCurrentQuestionId] = useState(null);
  const itemsPerPage = 5;

  // Function to fetch questions
  const fetchQuestions = () => {
    fetch(`https://mynewhomeschool.com/student/admin/api/questions_answers_handler.php?action=fetch&header=${encodeURIComponent(decodedHeader)}`)
      .then((res) => res.json())
      .then((data) => {
        setQuestions(data.questions || []);
      })
      .catch((error) => console.error("Error fetching questions:", error));
  };

  // Initial fetch
  useEffect(() => {
    console.log('Decoded Header:', decodedHeader);
    fetchQuestions();
  }, [decodedHeader]);

  // Filter questions based on search term
  useEffect(() => {
    const filtered = questions.filter(q =>
      q.question_text?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      q.question_header?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredQuestions(filtered);
  }, [searchTerm, questions]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const goBack = () => {
    navigate(-1);
  };

  // Question Handlers
  const handleAddQuestion = () => {
    setQuestionToEdit(null);
    setQuestionModalOpen(true);
  };

  const handleEditQuestion = (question) => {
    setQuestionToEdit(question);
    setQuestionModalOpen(true);
  };

  const handleDeleteQuestion = async (questionId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this question?");
    if (!confirmDelete) return;

    const response = await fetch('https://mynewhomeschool.com/student/admin/api/questions_answers_handler.php?action=delete', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: questionId, type: 'question' }),
    });
    if (response.ok) {
      // Re-fetch questions after deletion
      fetchQuestions();
    } else {
      console.error("Failed to delete question.");
      alert("Failed to delete question. Please try again.");
    }
  };

  const handleQuestionSubmit = (formData) => {
    const form = new FormData();
    form.append('question_header', formData.question_header);
    form.append('question_text', formData.question_text);
    form.append('additional_info', formData.additional_info);
    form.append('type', 'question');
    form.append('header', decodedHeader); // Associate question with header

    if (formData.question_image) {
      form.append('question_image', formData.question_image);
    }
    if (formData.additional_info_image) {
      form.append('additional_info_image', formData.additional_info_image);
    }
    if (questionToEdit) {
      form.append('id', questionToEdit.id);
      fetch('https://mynewhomeschool.com/student/admin/api/questions_answers_handler.php?action=update', {
        method: 'POST',
        body: form,
      })
        .then((res) => res.json())
        .then((updatedQuestion) => {
          // Re-fetch questions after update
          fetchQuestions();
        })
        .catch((error) => {
          console.error("Error updating question:", error);
          alert("Failed to update question. Please try again.");
        });
    } else {
      fetch('https://mynewhomeschool.com/student/admin/api/questions_answers_handler.php?action=add', {
        method: 'POST',
        body: form,
      })
        .then(res => res.json())
        .then(newQuestion => {
          // Re-fetch questions after adding
          fetchQuestions();
        })
        .catch((error) => {
          console.error("Error adding question:", error);
          alert("Failed to add question. Please try again.");
        });
    }
    setQuestionModalOpen(false);
  };

  // Answer Handlers
  const handleAddAnswer = (questionId) => {
    setCurrentQuestionId(questionId);
    setAnswerToEdit(null);
    setAnswerModalOpen(true);
  };

  const handleEditAnswer = (questionId, answer) => {
    setCurrentQuestionId(questionId);
    setAnswerToEdit(answer);
    setAnswerModalOpen(true);
  };

  const handleDeleteAnswer = async (questionId, answerId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this answer?");
    if (!confirmDelete) return;

    const response = await fetch('https://mynewhomeschool.com/student/admin/api/questions_answers_handler.php?action=delete', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: answerId, type: 'answer' }),
    });
    if (response.ok) {
      // Re-fetch questions after deletion
      fetchQuestions();
    } else {
      console.error("Failed to delete answer.");
      alert("Failed to delete answer. Please try again.");
    }
  };

  const handleAnswerSubmit = (formData) => {
    const form = new FormData();
    form.append('answer_text', formData.answer_text);
    form.append('is_correct', formData.is_correct ? 1 : 0);
    form.append('type', 'answer');
    form.append('question_id', currentQuestionId);
    if (formData.answer_image) {
      form.append('answer_image', formData.answer_image);
    }
    if (answerToEdit) {
      form.append('id', answerToEdit.id);
      fetch('https://mynewhomeschool.com/student/admin/api/questions_answers_handler.php?action=update', {
        method: 'POST',
        body: form,
      })
        .then((res) => res.json())
        .then((updatedAnswer) => {
          // Re-fetch questions after answer update
          fetchQuestions();
        })
        .catch((error) => {
          console.error("Error updating answer:", error);
          alert("Failed to update answer. Please try again.");
        });
    } else {
      fetch('https://mynewhomeschool.com/student/admin/api/questions_answers_handler.php?action=add', {
        method: 'POST',
        body: form,
      })
        .then(res => res.json())
        .then(newAnswer => {
          // Re-fetch questions after adding answer
          fetchQuestions();
        })
        .catch((error) => {
          console.error("Error adding answer:", error);
          alert("Failed to add answer. Please try again.");
        });
    }
    setAnswerModalOpen(false);
  };

  // Question Modal Component
  const QuestionModal = ({ open, onClose, onSubmit, question, header }) => {
    const [formData, setFormData] = useState({
      question_header: question?.question_header || header || '',
      question_text: question?.question_text || '',
      question_image: null,
      additional_info: question?.additional_info || '',
      additional_info_image: null,
    });

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
      const { name, files } = e.target;
      setFormData({ ...formData, [name]: files[0] });
    };

    const handleSubmit = () => {
      // Basic validation
      if (!formData.question_header.trim() || !formData.question_text.trim()) {
        alert("Question Header and Question Text are required.");
        return;
      }
      onSubmit(formData);
    };

    useEffect(() => {
      if (question) {
        setFormData({
          question_header: question.question_header || '',
          question_text: question.question_text || '',
          question_image: null,
          additional_info: question.additional_info || '',
          additional_info_image: null,
        });
      } else {
        setFormData(prev => ({
          ...prev,
          question_header: header || '',
        }));
      }
    }, [question, header]);

    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>{question ? 'Edit Question' : 'Add Question'}</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Question Header"
            name="question_header"
            value={formData.question_header}
            onChange={handleChange}
            fullWidth
            InputProps={{ readOnly: !!question }} // Make read-only when editing
          />
          <TextField
            margin="dense"
            label="Question Text"
            name="question_text"
            value={formData.question_text}
            onChange={handleChange}
            fullWidth
            multiline
            rows={4}
          />
          <Button
            variant="contained"
            component="label"
            sx={{ mt: 2 }}
          >
            Upload Question Image
            <input
              type="file"
              name="question_image"
              accept="image/*"
              hidden
              onChange={handleFileChange}
            />
          </Button>
          {formData.question_image && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle2">Preview:</Typography>
              <img
                src={URL.createObjectURL(formData.question_image)}
                alt="Question Preview"
                style={{ width: '150px', marginTop: '10px' }}
              />
            </Box>
          )}
          <TextField
            margin="dense"
            label="Additional Info"
            name="additional_info"
            value={formData.additional_info}
            onChange={handleChange}
            fullWidth
            multiline
            rows={3}
            sx={{ mt: 2 }}
          />
          <Button
            variant="contained"
            component="label"
            sx={{ mt: 2 }}
          >
            Upload Additional Info Image
            <input
              type="file"
              name="additional_info_image"
              accept="image/*"
              hidden
              onChange={handleFileChange}
            />
          </Button>
          {formData.additional_info_image && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle2">Preview:</Typography>
              <img
                src={URL.createObjectURL(formData.additional_info_image)}
                alt="Additional Info Preview"
                style={{ width: '150px', marginTop: '10px' }}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">Cancel</Button>
          <Button onClick={handleSubmit} color="primary">{question ? 'Update' : 'Add'}</Button>
        </DialogActions>
      </Dialog>
    );
  };

  // Answer Modal Component
  const AnswerModal = ({ open, onClose, onSubmit, answer }) => {
    const [formData, setFormData] = useState({
      answer_text: answer?.answer_text || '',
      answer_image: null,
      is_correct: answer?.is_correct == 1 ? true : false,
    });

    const handleChange = (e) => {
      const { name, value, type, checked } = e.target;
      setFormData({ ...formData, [name]: type === 'checkbox' ? checked : value });
    };

    const handleFileChange = (e) => {
      const { name, files } = e.target;
      setFormData({ ...formData, [name]: files[0] });
    };

    const handleSubmit = () => {
      // Basic validation
      if (!formData.answer_text.trim()) {
        alert("Answer Text is required.");
        return;
      }
      onSubmit(formData);
    };

    useEffect(() => {
      if (answer) {
        setFormData({
          answer_text: answer.answer_text || '',
          answer_image: null,
          is_correct: answer.is_correct == 1 ? true : false,
        });
      } else {
        setFormData({
          answer_text: '',
          answer_image: null,
          is_correct: false,
        });
      }
    }, [answer]);

    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>{answer ? 'Edit Answer' : 'Add Answer'}</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Answer Text"
            name="answer_text"
            value={formData.answer_text}
            onChange={handleChange}
            fullWidth
            multiline
            rows={3}
          />
          <Button
            variant="contained"
            component="label"
            sx={{ mt: 2 }}
          >
            Upload Answer Image
            <input
              type="file"
              name="answer_image"
              accept="image/*"
              hidden
              onChange={handleFileChange}
            />
          </Button>
          {formData.answer_image && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle2">Preview:</Typography>
              <img
                src={URL.createObjectURL(formData.answer_image)}
                alt="Answer Preview"
                style={{ width: '150px', marginTop: '10px' }}
              />
            </Box>
          )}
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.is_correct}
                onChange={handleChange}
                name="is_correct"
                color="primary"
              />
            }
            label="Is Correct"
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">Cancel</Button>
          <Button onClick={handleSubmit} color="primary">{answer ? 'Update' : 'Add'}</Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Box sx={{ p: 2 }}>
      {/* Back Button */}
      <Button onClick={goBack} color="secondary" variant="outlined" sx={{ mb: 2 }}>
        Back to Headers
      </Button>

      {/* Page Title */}
      <Typography variant="h5" sx={{ mb: 2 }}>
        Questions for Header: {decodedHeader}
      </Typography>

      {/* Search Bar */}
      <Paper component="form" sx={{ mb: 2, p: '2px 4px', display: 'flex', alignItems: 'center' }}>
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search questions"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Paper>

      {/* Add Question Button */}
      <Button
        onClick={handleAddQuestion}
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        sx={{ mb: 2 }}
      >
        Add Question
      </Button>

      {/* Render Questions */}
      {filteredQuestions.length > 0 ? (
        filteredQuestions
          .slice((page - 1) * itemsPerPage, page * itemsPerPage)
          .map((question) => (
            <QuestionCard
              key={question.id}
              question={question}
              handleEditAnswer={handleEditAnswer}
              handleDeleteAnswer={handleDeleteAnswer}
              handleEditQuestion={handleEditQuestion}
              handleDeleteQuestion={handleDeleteQuestion}
              handleAddAnswer={handleAddAnswer}
            />
          ))
      ) : (
        <Typography>No questions available</Typography>
      )}

      {/* Pagination */}
      <Pagination
        count={Math.ceil(filteredQuestions.length / itemsPerPage)}
        page={page}
        onChange={handlePageChange}
        sx={{ mt: 2 }}
        color="primary"
      />

      {/* Modals */}
      <QuestionModal
        open={questionModalOpen}
        onClose={() => setQuestionModalOpen(false)}
        onSubmit={handleQuestionSubmit}
        question={questionToEdit}
        header={decodedHeader}
      />

      <AnswerModal
        open={answerModalOpen}
        onClose={() => setAnswerModalOpen(false)}
        onSubmit={handleAnswerSubmit}
        answer={answerToEdit}
      />
    </Box>
  );
};

export default QuestionsPage;
