import React, { useState, useEffect } from 'react';
import {
  Box, Typography, List, ListItem, ListItemText, Button, Card, CardContent,
  IconButton, Dialog, DialogTitle, DialogContent, TextField, DialogActions,
  Pagination, InputBase, Paper, CardMedia, Divider, CircularProgress
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

// Answer Card Component
const AnswerCard = ({ answer, onEdit, onDelete }) => (
  <Card sx={{ display: 'flex', mb: 2, p: 2 }}>
    <Box sx={{ flexGrow: 1 }}>
      <CardContent>
        <Typography variant="body1">{answer.answer_text}</Typography>
        {answer.answer_image && (
          <img
            src={`https://mynewhomeschool.com/student/admin/${answer.answer_image}`}
            alt="Answer"
            style={{ width: '150px', marginTop: '10px' }}
          />
        )}
        <Typography variant="body2">
          {answer.is_correct ? "Correct Answer" : "Incorrect Answer"}
        </Typography>
      </CardContent>
    </Box>
    <Box>
      <IconButton onClick={() => onEdit(answer)} color="primary">
        <EditIcon />
      </IconButton>
      <IconButton onClick={() => onDelete(answer.id)} color="secondary">
        <DeleteIcon />
      </IconButton>
    </Box>
  </Card>
);

// Question Card Component
const QuestionCard = ({ question, answers, onEdit, onDelete, onAddAnswer }) => (
  <Card sx={{ mb: 2, p: 2 }}>
    <CardContent>
      <Typography variant="h6">{question.question_header}</Typography>
      <Typography variant="body1">{question.question_text}</Typography>
      {question.question_image && (
        <img
          src={`https://mynewhomeschool.com/student/admin/${question.question_image}`}
          alt="Question"
          style={{ width: '150px', marginTop: '10px' }}
        />
      )}
      {question.additional_info && (
        <Typography variant="body2" sx={{ mt: 2 }}>
          Additional Info: {question.additional_info}
        </Typography>
      )}
      {question.additional_info_image && (
        <CardMedia
          component="img"
          sx={{ width: 100, marginTop: '15px' }}
          image={`https://mynewhomeschool.com/student/admin/${question.additional_info_image}`}
          alt="Additional Info Image"
        />
      )}
    </CardContent>
    <Box>
      <Typography variant="h6" sx={{ mt: 2 }}>Answers:</Typography>
      {answers.map((answer) => (
        <AnswerCard
          key={answer.id}
          answer={answer}
          onEdit={() => {}}
          onDelete={() => {}}
        />
      ))}
    </Box>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
      <IconButton onClick={() => onEdit(question)} color="primary">
        <EditIcon />
      </IconButton>
      <IconButton onClick={() => onDelete(question.id)} color="secondary">
        <DeleteIcon />
      </IconButton>
      <Button onClick={() => onAddAnswer(question)} variant="contained" color="primary">
        Add Answer
      </Button>
    </Box>
  </Card>
);

// Add/Edit Question Modal
const QuestionModal = ({ open, onClose, onSubmit, question }) => {
  const [formData, setFormData] = useState({
    question_header: question?.question_header || '',
    question_text: question?.question_text || '',
    question_image: '',
    additional_info: question?.additional_info || '',
    additional_info_image: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const { files } = e.target;
    if (files.length > 0) {
      setFormData({ ...formData, [e.target.name]: URL.createObjectURL(files[0]) });
    }
  };

  const handleSubmit = () => {
    onSubmit(formData);
  };

  useEffect(() => {
    if (question) {
      setFormData({
        question_header: question.question_header || '',
        question_text: question.question_text || '',
        question_image: '',
        additional_info: question.additional_info || '',
        additional_info_image: ''
      });
    }
  }, [question]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{question ? 'Edit Question' : 'Add Question'}</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Question Header"
          name="question_header"
          value={formData.question_header}
          onChange={handleChange}
          fullWidth
        />
        <TextField
          margin="dense"
          label="Question Text"
          name="question_text"
          value={formData.question_text}
          onChange={handleChange}
          fullWidth
        />
        <input
          type="file"
          name="question_image"
          accept="image/*"
          onChange={handleFileChange}
          style={{ marginTop: '15px' }}
        />
        {formData.question_image && (
          <CardMedia
            component="img"
            sx={{ width: 100, marginTop: '15px' }}
            image={formData.question_image}
            alt="Question Image Preview"
          />
        )}
        <TextField
          margin="dense"
          label="Additional Info"
          name="additional_info"
          value={formData.additional_info}
          onChange={handleChange}
          fullWidth
        />
        <input
          type="file"
          name="additional_info_image"
          accept="image/*"
          onChange={handleFileChange}
          style={{ marginTop: '15px' }}
        />
        {formData.additional_info_image && (
          <CardMedia
            component="img"
            sx={{ width: 100, marginTop: '15px' }}
            image={formData.additional_info_image}
            alt="Additional Info Image Preview"
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">Cancel</Button>
        <Button onClick={handleSubmit} color="primary">{question ? 'Update' : 'Add'}</Button>
      </DialogActions>
    </Dialog>
  );
};

// Main Paid Questions Management Component
const PaidQuestionsManagement = () => {
  const [loading, setLoading] = useState(true); // Loading state
  const [levels, setLevels] = useState(JSON.parse(sessionStorage.getItem('levels')) || []);
  const [units, setUnits] = useState(JSON.parse(sessionStorage.getItem('units')) || []);
  const [questions, setQuestions] = useState([]);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [questionHeaders, setQuestionHeaders] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState(JSON.parse(sessionStorage.getItem('selectedLevel')) || null);
  const [selectedUnit, setSelectedUnit] = useState(JSON.parse(sessionStorage.getItem('selectedUnit')) || null);
  const [selectedHeader, setSelectedHeader] = useState(null);
  const [questionModalOpen, setQuestionModalOpen] = useState(false);
  const [questionToEdit, setQuestionToEdit] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const itemsPerPage = 5;

  const navigate = useNavigate();
  

  // Fetch levels
  useEffect(() => {
    if (levels.length === 0) {
      setLoading(true);
      fetch('https://mynewhomeschool.com/student/admin/api/paid_questions_management.php?action=fetch_levels')
        .then((res) => res.json())
        .then((data) => {
          setLevels(data.levels);
          sessionStorage.setItem('levels', JSON.stringify(data.levels));
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [levels]);

  // Fetch units when level is selected
  useEffect(() => {
    if (selectedLevel) {
      setLoading(true);
      fetch(`https://mynewhomeschool.com/student/admin/api/paid_questions_management.php?action=fetch_units&level_id=${selectedLevel.id}`)
        .then((res) => res.json())
        .then((data) => {
          setUnits(data.units);
          sessionStorage.setItem('units', JSON.stringify(data.units));
          sessionStorage.setItem('selectedLevel', JSON.stringify(selectedLevel));
        })
        .finally(() => setLoading(false));
    }
  }, [selectedLevel]);

  // Fetch questions when unit is selected
  useEffect(() => {
    if (selectedUnit) {
      setLoading(true);
      fetch(`https://mynewhomeschool.com/student/admin/api/paid_questions_management.php?action=fetch_questions_by_unit&unit_id=${selectedUnit.id}`)
        .then((res) => res.json())
        .then((data) => {
          setQuestions(data.questions);
          const headers = [...new Set(data.questions.map((q) => q.question_header))];
          setQuestionHeaders(headers);
          sessionStorage.setItem('selectedUnit', JSON.stringify(selectedUnit));
        })
        .finally(() => setLoading(false));
    }
  }, [selectedUnit]);

  // Filter questions by header and search term
  useEffect(() => {
    const filtered = questions.filter(q =>
      q.question_header === selectedHeader &&
      q.question_text.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredQuestions(filtered);
  }, [selectedHeader, searchTerm, questions]);

  const handleLevelClick = (level) => {
    setSelectedLevel(level);
    setSelectedUnit(null); // Reset selected unit
    setSelectedHeader(null); // Reset selected header
    setUnits([]);
    setQuestions([]);
    setQuestionHeaders([]);
  };

  const handleUnitClick = (unit) => {
    setSelectedUnit(unit);
    setSelectedHeader(null); // Reset selected header
    setQuestions([]);
    setQuestionHeaders([]);
  };

  const handleHeaderClick = (header) => {
    navigate(`/admin/onlinep/questions/${header}`);
  };

  const handleEditQuestion = (question) => {
    setQuestionToEdit(question);
    setQuestionModalOpen(true);
  };

  const handleDeleteQuestion = (questionId) => {
    // Call API to delete question
  };

  const handleAddQuestion = () => {
    setQuestionToEdit(null);
    setQuestionModalOpen(true);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      {/* Sidebar: Levels and Units */}
      <Box sx={{ width: '25%', p: 2, bgcolor: '#f4f4f4', position: 'sticky', top: 0 }}>
        {loading ? (
          <CircularProgress />
        ) : !selectedLevel ? (
          <>
            <Typography variant="h6">Levels</Typography>
            <List>
              {levels.map((level) => (
                <ListItem button key={level.id} onClick={() => handleLevelClick(level)}>
                  <ListItemText primary={level.title} />
                </ListItem>
              ))}
            </List>
          </>
        ) : (
          <>
            <Typography variant="h6">Units for {selectedLevel.title}</Typography>
            <Button onClick={() => setSelectedLevel(null)} color="secondary">Back to Levels</Button>
            <List>
              {units.map((unit) => (
                <ListItem button key={unit.id} onClick={() => handleUnitClick(unit)}>
                  <ListItemText primary={unit.title} />
                </ListItem>
              ))}
            </List>
          </>
        )}
      </Box>

      {/* Main Content: Questions and Answers */}
      <Box sx={{ width: '75%', p: 2 }}>
        {loading ? (
          <CircularProgress />
        ) : selectedUnit && (
          <>
            <Typography variant="h6">Question Headers for {selectedUnit.title}</Typography>
            <Button onClick={() => setSelectedUnit(null)} color="secondary">Back to Units</Button>
            <List>
              {questionHeaders.map((header, idx) => (
                <ListItem button key={idx} onClick={() => handleHeaderClick(header)}>
                  <ListItemText primary={header} />
                </ListItem>
              ))}
            </List>
          </>
        )}

        {filteredQuestions.length > 0 && !loading && (
          <>
            <Divider sx={{ my: 2 }} />
            <Paper component="form" sx={{ mb: 2, p: '2px 4px', display: 'flex', alignItems: 'center' }}>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search questions"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Paper>

            <Typography variant="h6">Questions for Header: {selectedHeader}</Typography>
            {filteredQuestions.slice((page - 1) * itemsPerPage, page * itemsPerPage).map((question) => (
              <QuestionCard
                key={question.id}
                question={question}
                answers={question.answers}
                onEdit={handleEditQuestion}
                onDelete={handleDeleteQuestion}
                onAddAnswer={() => {}}
              />
            ))}

            <Pagination
              count={Math.ceil(filteredQuestions.length / itemsPerPage)}
              page={page}
              onChange={handlePageChange}
              sx={{ mt: 2 }}
            />
          </>
        )}
      </Box>

      {/* Add/Edit Question Modal */}
      <QuestionModal
        open={questionModalOpen}
        onClose={() => setQuestionModalOpen(false)}
        onSubmit={() => {}}
        question={questionToEdit}
      />
    </Box>
  );
};

export default PaidQuestionsManagement;
