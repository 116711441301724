import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Card,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';

const TopRankedStudentsManagement = () => {
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [levels, setLevels] = useState([]);

  const [studentData, setStudentData] = useState({
    id: '',
    name: '',
    parent_name: '',
    level_id: '',
    profile_photo: '',
    existing_profile_photo: '',
  });

  useEffect(() => {
    fetchStudents();
    fetchLevels();
  }, []);
  
  const fetchLevels = async () => {
    try {
      const response = await fetch('https://mynewhomeschool.com/student/admin/api/top_st.php?action=fetch_levels');
      const data = await response.json();
      if (data.success) {
        setLevels(data.levels);
      } else {
        console.error('Error fetching levels:', data.message);
      }
    } catch (error) {
      console.error('Error fetching levels:', error);
    }
  };  

  const fetchStudents = async () => {
    setLoading(true);
    try {
      const response = await fetch('https://mynewhomeschool.com/student/admin/api/top_st.php?action=fetch_students');
      const data = await response.json();
      setStudents(data);
    } catch (error) {
      console.error('Error fetching students:', error);
    }
    setLoading(false);
  };

  const handleDialogOpen = (student = {}) => {
    setStudentData({
      ...student,
      existing_profile_photo: student.profile_photo || '',
      profile_photo: '',
    });
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setStudentData({
      id: '',
      name: '',
      parent_name: '',
      level_id: '',
      profile_photo: '',
      existing_profile_photo: '',
    });
    setOpenDialog(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    Object.keys(studentData).forEach((key) => {
      if (key === 'profile_photo' && !studentData[key]) {
        return; // Skip if no new profile photo
      }
      formData.append(key, studentData[key]);
    });

    const action = studentData.id ? 'update_student' : 'add_student';
    const url = `https://mynewhomeschool.com/student/admin/api/top_st.php?action=${action}`;

    try {
      const response = await fetch(url, {
        method: 'POST',
        body: formData,
      });
      const result = await response.json();
      if (result.success) {
        fetchStudents();
        handleDialogClose();
      } else {
        console.error('Error submitting student:', result.message);
      }
    } catch (error) {
      console.error('Error submitting student:', error);
    }
    setLoading(false);
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this student?')) {
      try {
        const response = await fetch(`https://mynewhomeschool.com/student/admin/api/top_st.php?action=delete_student&id=${id}`, {
          method: 'GET',
        });
        const result = await response.json();
        if (result.success) {
          fetchStudents();
        }
      } catch (error) {
        console.error('Error deleting student:', error);
      }
    }
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Card sx={{ padding: 3 }}>
        <Typography variant="h4" gutterBottom>
          Top Ranked Students Management
        </Typography>

        <Button variant="contained" color="primary" onClick={() => handleDialogOpen()}>
          Add New Student
        </Button>

        {loading ? (
          <CircularProgress sx={{ display: 'block', margin: '20px auto' }} />
        ) : (
          <TableContainer component={Paper} sx={{ marginTop: 3 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Parent Name</TableCell>
                  <TableCell>Level</TableCell>
                  <TableCell>Profile Photo</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {students.map((student) => (
                  <TableRow key={student.id}>
                    <TableCell>{student.name}</TableCell>
                    <TableCell>{student.parent_name}</TableCell>
                    <TableCell>{student.level_id}</TableCell>
                    <TableCell>
                      {student.profile_photo && (
                        <img src={`https://mynewhomeschool.com/student/admin/uploads/${student.profile_photo}`} alt={student.name} width="50" height="50" />
                      )}
                    </TableCell>
                    <TableCell>
                      <IconButton color="primary" onClick={() => handleDialogOpen(student)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton color="error" onClick={() => handleDelete(student.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {/* Dialog for Adding/Editing Student */}
        <Dialog open={openDialog} onClose={handleDialogClose}>
          <DialogTitle>{studentData.id ? 'Edit Student' : 'Add New Student'}</DialogTitle>
          <DialogContent>
            <TextField
              label="Name"
              value={studentData.name}
              onChange={(e) => setStudentData({ ...studentData, name: e.target.value })}
              fullWidth
              sx={{ mb: 2 }}
              InputLabelProps={{ shrink: studentData.name ? true : undefined }}
            />
            <TextField
              label="Parent Name"
              value={studentData.parent_name}
              onChange={(e) => setStudentData({ ...studentData, parent_name: e.target.value })}
              fullWidth
              sx={{ mb: 2 }}
              InputLabelProps={{ shrink: studentData.parent_name ? true : undefined }}
            />
            <FormControl fullWidth sx={{ mb: 2 }}>
  <InputLabel>Level</InputLabel>
  <Select
    label="Level"
    value={studentData.level_id}
    onChange={(e) => setStudentData({ ...studentData, level_id: e.target.value })}
  >
    {levels.map((level) => (
      <MenuItem key={level.id} value={level.id}>
        {level.name}
      </MenuItem>
    ))}
  </Select>
</FormControl>

            <TextField
              type="file"
              fullWidth
              sx={{ mb: 2 }}
              onChange={(e) => setStudentData({ ...studentData, profile_photo: e.target.files[0] })}
            />
            {studentData.existing_profile_photo && (
              <Typography variant="caption" sx={{ mb: 2 }}>
                Current Profile Photo: {studentData.existing_profile_photo}
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>Cancel</Button>
            <Button onClick={handleSubmit} color="primary">
              {studentData.id ? 'Update' : 'Add'}
            </Button>
          </DialogActions>
        </Dialog>
      </Card>
    </Box>
  );
};

export default TopRankedStudentsManagement;
