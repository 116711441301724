// EssayQuestionsReviewPage.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  IconButton,
  Pagination,
  Paper,
  InputBase,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  Backdrop,
  CircularProgress,
  Tooltip,
  TextField,
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  ArrowBack as ArrowBackIcon,
  CheckCircle as CheckCircleIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import he from 'he'; // For decoding HTML entities
import DOMPurify from 'dompurify'; // For sanitizing HTML

const EssayQuestionsReviewPage = () => {
  // Define itemsPerPage at the top for accessibility throughout the component
  const itemsPerPage = 5;

  const [headers, setHeaders] = useState([]); // List of unique headers with approved=0
  const [selectedHeader, setSelectedHeader] = useState(null); // Currently selected header
  const [questions, setQuestions] = useState([]); // Questions under the selected header
  const [searchTerm, setSearchTerm] = useState(''); // Search term for filtering questions
  const [filteredQuestions, setFilteredQuestions] = useState([]); // Filtered questions based on search
  const [page, setPage] = useState(1); // Current pagination page
  const [loading, setLoading] = useState(false); // Loading state
  const [approveConfirmOpen, setApproveConfirmOpen] = useState(false); // Confirmation dialog for approving
  const [questionModalOpen, setQuestionModalOpen] = useState(false); // Modal for adding/editing questions
  const [questionToEdit, setQuestionToEdit] = useState(null); // Question being edited
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false); // Confirmation dialog for deletion
  const [questionToDelete, setQuestionToDelete] = useState(null); // Question being deleted

  const navigate = useNavigate();

  // Fetch all unique headers with approved=0 on component mount
  useEffect(() => {
    fetchPendingHeaders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch pending headers function
  const fetchPendingHeaders = () => {
    setLoading(true);
    fetch(`https://mynewhomeschool.com/student/admin/api/essay_review_handler.php?action=fetch_pending_headers`)
      .then((res) => res.json())
      .then((data) => {
        setHeaders(data.headers || []);
      })
      .catch((error) => {
        console.error("Error fetching pending headers:", error);
        alert("Failed to fetch headers. Please try again.");
      })
      .finally(() => setLoading(false));
  };

  // Fetch questions under a specific header
  const fetchQuestionsByHeader = (header) => {
    setLoading(true);
    fetch(`https://mynewhomeschool.com/student/admin/api/essay_review_handler.php?action=fetch_questions_by_header&header=${encodeURIComponent(header)}`)
      .then((res) => res.json())
      .then((data) => {
        setQuestions(data.questions || []);
        setFilteredQuestions(data.questions || []);
        setPage(1); // Reset to first page
      })
      .catch((error) => {
        console.error("Error fetching questions:", error);
        alert("Failed to fetch questions. Please try again.");
      })
      .finally(() => setLoading(false));
  };

  // Handle header click
  const handleHeaderClick = (header) => {
    setSelectedHeader(header);
    fetchQuestionsByHeader(header);
  };
  const handleApproveAllQuestions = () => {
    if (!selectedHeader) {
      alert("No header selected.");
      return;
    }
    setApproveConfirmOpen(true);
  };

  // Handle Approve All button click
  const handleApproveAll = () => {
    setApproveConfirmOpen(true);
  };

  // Confirm approve all
  const confirmApproveAll = () => {
    setApproveConfirmOpen(false);
    setLoading(true);
    fetch(`https://mynewhomeschool.com/student/admin/api/essay_review_handler.php?action=approve_all_by_header`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ header: selectedHeader }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          alert(data.message || "All questions approved successfully.");
          // Refresh headers and questions
          fetchPendingHeaders();
          setSelectedHeader(null);
          setQuestions([]);
          setFilteredQuestions([]);
        } else {
          alert(data.message || "Failed to approve questions.");
        }
      })
      .catch((error) => {
        console.error("Error approving questions:", error);
        alert("Failed to approve questions. Please try again.");
      })
      .finally(() => setLoading(false));
  };

  // Handle Approve All cancellation
  const handleApproveCancel = () => {
    setApproveConfirmOpen(false);
  };

  // Handle Edit Question
  const handleEditQuestion = (question) => {
    setQuestionToEdit(question);
    setQuestionModalOpen(true);
  };

  // Handle Delete Question
  const handleDeleteQuestion = (questionId) => {
    const question = questions.find(q => q.id === questionId);
    setQuestionToDelete(question);
    setDeleteConfirmOpen(true);
  };

  // Confirm Delete Question
  const confirmDeleteQuestion = async () => {
    setDeleteConfirmOpen(false);
    if (!questionToDelete) return;
    setLoading(true);
    try {
      const response = await fetch('https://mynewhomeschool.com/student/admin/api/essay_review_handler.php?action=delete', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id: questionToDelete.id }),
      });
      const data = await response.json();
      if (response.ok && data.success) {
        alert(data.message || "Question deleted successfully.");
        // Refresh questions
        fetchQuestionsByHeader(selectedHeader);
      } else {
        alert(data.message || "Failed to delete question.");
      }
    } catch (error) {
      console.error("Error deleting question:", error);
      alert("Failed to delete question. Please try again.");
    }
    setLoading(false);
    setQuestionToDelete(null);
  };

  // Cancel Delete Question
  const handleDeleteCancel = () => {
    setDeleteConfirmOpen(false);
    setQuestionToDelete(null);
  };

  // Handle Add/Edit Question Submission
  const handleQuestionSubmit = (formData) => {
    setQuestionModalOpen(false);
    setLoading(true);
    const form = new FormData();
    form.append('question_header', formData.question_header);
    form.append('question_text', formData.question_text);
    form.append('answer_text', formData.answer_text);
    form.append('additional_info', formData.additional_info);
    form.append('type', 'essay_question');
    form.append('level_id', formData.level_id || 0); // Adjust if needed
    form.append('unit_id', formData.unit_id || 0); // Adjust if needed

    if (formData.question_image) {
      form.append('question_image', formData.question_image);
    }
    if (formData.answer_image) {
      form.append('answer_image', formData.answer_image);
    }
    if (formData.additional_info_image) {
      form.append('additional_info_image', formData.additional_info_image);
    }
    if (questionToEdit) {
      form.append('id', questionToEdit.id);
      fetch('https://mynewhomeschool.com/student/admin/api/essay_review_handler.php?action=update', {
        method: 'POST',
        body: form,
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            alert(data.message || "Question updated successfully.");
            // Refresh questions
            fetchQuestionsByHeader(selectedHeader);
            setQuestionToEdit(null);
          } else {
            alert(data.message || "Failed to update question.");
          }
        })
        .catch((error) => {
          console.error("Error updating question:", error);
          alert("Failed to update question. Please try again.");
        })
        .finally(() => setLoading(false));
    } else {
      fetch('https://mynewhomeschool.com/student/admin/api/essay_review_handler.php?action=add', {
        method: 'POST',
        body: form,
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            alert(data.message || "Question added successfully.");
            // Refresh questions
            fetchQuestionsByHeader(selectedHeader);
          } else {
            alert(data.message || "Failed to add question.");
          }
        })
        .catch((error) => {
          console.error("Error adding question:", error);
          alert("Failed to add question. Please try again.");
        })
        .finally(() => setLoading(false));
    }
  };

  // Question Card Component
  const QuestionCard = ({
    question,
    handleEditQuestion,
    handleDeleteQuestion,
  }) => {
    // Function to decode and sanitize HTML entities
    const decodeAndSanitize = (html) => {
      if (!html) return '';
      const decoded = he.decode(html);
      return DOMPurify.sanitize(decoded);
    };

    return (
      <Card sx={{ mb: 2, p: 2, borderLeft: question.approved === 1 ? '5px solid green' : '5px solid red' }}>
        <CardContent>
          {/* Render Question Header with Bold and Italic Formatting */}
          <Typography
            variant="h6"
            dangerouslySetInnerHTML={{
              __html: decodeAndSanitize(question.question_header) || 'No header provided',
            }}
          />

          {/* Render Question Text with Bold and Italic Formatting */}
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{
              __html: decodeAndSanitize(question.question_text) || 'No question text provided',
            }}
          />

          {/* Render Question Image if available */}
          {question.question_image && (
            <img
              src={`https://mynewhomeschool.com/student/admin/${question.question_image}`}
              alt="Question"
              style={{ width: '150px', marginTop: '10px' }}
              loading="lazy" // Lazy loading for performance
            />
          )}

          {/* Render Answer Text with Bold and Italic Formatting */}
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{
              __html: `<strong>Answer:</strong> ${decodeAndSanitize(question.answer_text) || 'No answer text provided'}`,
            }}
            sx={{ mt: 2 }}
          />

          {/* Render Answer Image if available */}
          {question.answer_image && (
            <img
              src={`https://mynewhomeschool.com/student/admin/${question.answer_image}`}
              alt="Answer"
              style={{ width: '150px', marginTop: '10px' }}
              loading="lazy" // Lazy loading for performance
            />
          )}

          {/* Render Additional Info with Bold and Italic Formatting */}
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{
              __html: `<strong>Additional Info:</strong> ${decodeAndSanitize(question.additional_info) || 'No additional info provided'}`,
            }}
            sx={{ mt: 2 }}
          />

          {/* Render Additional Info Image if available */}
          {question.additional_info_image && (
            <img
              src={`https://mynewhomeschool.com/student/admin/${question.additional_info_image}`}
              alt="Additional Info"
              style={{ width: '150px', marginTop: '10px' }}
              loading="lazy" // Lazy loading for performance
            />
          )}
        </CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          {/* Edit Question Button */}
          {/* <Tooltip title="Edit Question">
            <IconButton
              color="primary"
              onClick={() => handleEditQuestion(question)}
              aria-label="edit question"
            >
              <EditIcon />
            </IconButton>
          </Tooltip> */}

          {/* Delete Question Button */}
          <Tooltip title="Delete Question">
            <IconButton
              color="secondary"
              onClick={() => handleDeleteQuestion(question.id)}
              aria-label="delete question"
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Card>
    );
  };

  // Question Modal Component
  const QuestionModal = ({ open, onClose, onSubmit, question, selectedHeader }) => {
    const [formData, setFormData] = useState({
      question_header: question?.question_header || selectedHeader || '',
      question_text: question?.question_text || '',
      question_image: null,
      answer_text: question?.answer_text || '',
      answer_image: null,
      additional_info: question?.additional_info || '',
      additional_info_image: null,
    });

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
      const { name, files } = e.target;
      setFormData({ ...formData, [name]: files[0] });
    };

    const handleSubmit = () => {
      // Basic form validation
      if (!formData.question_header.trim() || !formData.question_text.trim()) {
        alert("Question Header and Question Text are required.");
        return;
      }
      onSubmit(formData);
    };

    useEffect(() => {
      if (question) {
        setFormData({
          question_header: question.question_header || '',
          question_text: question.question_text || '',
          question_image: null,
          answer_text: question.answer_text || '',
          answer_image: null,
          additional_info: question.additional_info || '',
          additional_info_image: null,
        });
      } else {
        setFormData({
          question_header: selectedHeader || '',
          question_text: '',
          question_image: null,
          answer_text: '',
          answer_image: null,
          additional_info: '',
          additional_info_image: null,
        });
      }
    }, [question, selectedHeader]);

    return (
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>{question ? 'Edit Essay Question' : 'Add Essay Question'}</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              label="Question Header"
              name="question_header"
              value={formData.question_header}
              onChange={handleChange}
              fullWidth
              disabled={!!question} // Disable editing header when editing existing question
            />
            <TextField
              label="Question Text"
              name="question_text"
              value={formData.question_text}
              onChange={handleChange}
              multiline
              rows={4}
              fullWidth
            />
            {/* Question Image Upload */}
            <Button
              variant="contained"
              component="label"
            >
              Upload Question Image
              <input
                type="file"
                name="question_image"
                accept="image/*"
                hidden
                onChange={handleFileChange}
              />
            </Button>
            {formData.question_image && (
              <Box>
                <Typography variant="subtitle2">Question Image Preview:</Typography>
                <img
                  src={URL.createObjectURL(formData.question_image)}
                  alt="Question"
                  style={{ width: '150px', marginTop: '10px' }}
                />
              </Box>
            )}

            <TextField
              label="Answer Text"
              name="answer_text"
              value={formData.answer_text}
              onChange={handleChange}
              multiline
              rows={4}
              fullWidth
            />
            {/* Answer Image Upload */}
            <Button
              variant="contained"
              component="label"
            >
              Upload Answer Image
              <input
                type="file"
                name="answer_image"
                accept="image/*"
                hidden
                onChange={handleFileChange}
              />
            </Button>
            {formData.answer_image && (
              <Box>
                <Typography variant="subtitle2">Answer Image Preview:</Typography>
                <img
                  src={URL.createObjectURL(formData.answer_image)}
                  alt="Answer"
                  style={{ width: '150px', marginTop: '10px' }}
                />
              </Box>
            )}

            <TextField
              label="Additional Info"
              name="additional_info"
              value={formData.additional_info}
              onChange={handleChange}
              multiline
              rows={2}
              fullWidth
            />
            {/* Additional Info Image Upload */}
            <Button
              variant="contained"
              component="label"
            >
              Upload Additional Info Image
              <input
                type="file"
                name="additional_info_image"
                accept="image/*"
                hidden
                onChange={handleFileChange}
              />
            </Button>
            {formData.additional_info_image && (
              <Box>
                <Typography variant="subtitle2">Additional Info Image Preview:</Typography>
                <img
                  src={URL.createObjectURL(formData.additional_info_image)}
                  alt="Additional Info"
                  style={{ width: '150px', marginTop: '10px' }}
                />
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">Cancel</Button>
          <Button onClick={handleSubmit} color="primary">{question ? 'Update' : 'Add'}</Button>
        </DialogActions>
      </Dialog>
    )};

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      {/* Backdrop for Loading Spinner */}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* Sidebar */}
      <Box sx={{ width: '300px', borderRight: '1px solid #ccc', p: 2, overflowY: 'auto' }}>
        <Typography variant="h5" sx={{ mb: 2 }}>Essay Questions Review</Typography>
        <Typography variant="h6">Pending Headers</Typography>
        {headers.length > 0 ? (
          <List>
            {headers.map((header, index) => (
              <ListItem
                button
                key={index}
                onClick={() => handleHeaderClick(header)}
                sx={{ borderBottom: '1px solid #eee' }}
              >
                <ListItemText
                  primary={header}
                  primaryTypographyProps={{ fontWeight: 'bold' }}
                />
                <Tooltip title="Approve All">
                  <IconButton
                    edge="end"
                    color="success"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent triggering header click
                      setSelectedHeader(header);
                      setApproveConfirmOpen(true);
                    }}
                    disabled={false} // You can add conditions to disable if needed
                  >
                    <CheckCircleIcon />
                  </IconButton>
                </Tooltip>
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography>No pending headers.</Typography>
        )}
      </Box>

      {/* Main Content */}
      <Box sx={{ flexGrow: 1, p: 3, overflowY: 'auto' }}>
        {selectedHeader ? (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <IconButton onClick={() => setSelectedHeader(null)} color="primary">
                <ArrowBackIcon />
              </IconButton>
              <Typography variant="h5" sx={{ ml: 1 }}>
                Questions under: "{selectedHeader}"
              </Typography>
            </Box>

            {/* Search Bar */}
            <Paper
              component="form"
              sx={{ mb: 2, p: '2px 4px', display: 'flex', alignItems: 'center' }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search essay questions"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Paper>

            {/* Approve All Button */}
            <Button
              onClick={handleApproveAllQuestions}
              variant="contained"
              color="success"
              startIcon={<CheckCircleIcon />}
              sx={{ mb: 2 }}
            >
              Approve All
            </Button>

            {/* Add Question Button */}
            {/* <Button
              onClick={() => {
                setQuestionToEdit(null);
                setQuestionModalOpen(true);
              }}
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              sx={{ mb: 2, ml: 2 }}
            >
              Add Essay Question
            </Button> */}

            {/* Render Questions */}
            {filteredQuestions.filter(q => q.question_header === selectedHeader).length > 0 ? (
              <>
                {filteredQuestions
                  .filter(q => q.question_header === selectedHeader)
                  .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                  .map((question) => (
                    <QuestionCard
                      key={question.id}
                      question={question}
                      handleEditQuestion={handleEditQuestion}
                      handleDeleteQuestion={handleDeleteQuestion}
                    />
                  ))}

                {/* Pagination */}
                <Pagination
                  count={Math.ceil(filteredQuestions.filter(q => q.question_header === selectedHeader).length / itemsPerPage)}
                  page={page}
                  onChange={(e, value) => setPage(value)}
                  sx={{ mt: 2 }}
                  color="primary"
                />
              </>
            ) : (
              <Typography>No essay questions available under this header.</Typography>
            )}
          </>
        ) : (
          <Typography variant="h6">Select a header from the sidebar to review essay questions.</Typography>
        )}
      </Box>

      {/* Approve All Confirmation Dialog */}
      <Dialog
        open={approveConfirmOpen}
        onClose={handleApproveCancel}
      >
        <DialogTitle>Confirm Approval</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to approve all questions under "{selectedHeader}"?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleApproveCancel} color="secondary">
            Cancel
          </Button>
          <Button onClick={confirmApproveAll} color="primary">
            Approve All
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteConfirmOpen}
        onClose={handleDeleteCancel}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this question?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="secondary">
            Cancel
          </Button>
          <Button onClick={confirmDeleteQuestion} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add/Edit Question Modal */}
      <QuestionModal
        open={questionModalOpen}
        onClose={() => setQuestionModalOpen(false)}
        onSubmit={handleQuestionSubmit}
        question={questionToEdit}
        selectedHeader={selectedHeader}
      />
      </Box>
    )};

    // Question Modal Component defined inside the main component to access imports
    const QuestionModal = ({ open, onClose, onSubmit, question, selectedHeader }) => {
      const [formData, setFormData] = useState({
        question_header: question?.question_header || selectedHeader || '',
        question_text: question?.question_text || '',
        question_image: null,
        answer_text: question?.answer_text || '',
        answer_image: null,
        additional_info: question?.additional_info || '',
        additional_info_image: null,
      });

      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };

      const handleFileChange = (e) => {
        const { name, files } = e.target;
        setFormData({ ...formData, [name]: files[0] });
      };

      const handleSubmit = () => {
        // Basic form validation
        if (!formData.question_header.trim() || !formData.question_text.trim()) {
          alert("Question Header and Question Text are required.");
          return;
        }
        onSubmit(formData);
      };

      useEffect(() => {
        if (question) {
          setFormData({
            question_header: question.question_header || '',
            question_text: question.question_text || '',
            question_image: null,
            answer_text: question.answer_text || '',
            answer_image: null,
            additional_info: question.additional_info || '',
            additional_info_image: null,
          });
        } else {
          setFormData({
            question_header: selectedHeader || '',
            question_text: '',
            question_image: null,
            answer_text: '',
            answer_image: null,
            additional_info: '',
            additional_info_image: null,
          });
        }
      }, [question, selectedHeader]);

      return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
          <DialogTitle>{question ? 'Edit Essay Question' : 'Add Essay Question'}</DialogTitle>
          <DialogContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <TextField
                label="Question Header"
                name="question_header"
                value={formData.question_header}
                onChange={handleChange}
                fullWidth
                disabled={!!question} // Disable editing header when editing existing question
              />
              <TextField
                label="Question Text"
                name="question_text"
                value={formData.question_text}
                onChange={handleChange}
                multiline
                rows={4}
                fullWidth
              />
              {/* Question Image Upload */}
              <Button
                variant="contained"
                component="label"
              >
                Upload Question Image
                <input
                  type="file"
                  name="question_image"
                  accept="image/*"
                  hidden
                  onChange={handleFileChange}
                />
              </Button>
              {formData.question_image && (
                <Box>
                  <Typography variant="subtitle2">Question Image Preview:</Typography>
                  <img
                    src={URL.createObjectURL(formData.question_image)}
                    alt="Question"
                    style={{ width: '150px', marginTop: '10px' }}
                  />
                </Box>
              )}

              <TextField
                label="Answer Text"
                name="answer_text"
                value={formData.answer_text}
                onChange={handleChange}
                multiline
                rows={4}
                fullWidth
              />
              {/* Answer Image Upload */}
              <Button
                variant="contained"
                component="label"
              >
                Upload Answer Image
                <input
                  type="file"
                  name="answer_image"
                  accept="image/*"
                  hidden
                  onChange={handleFileChange}
                />
              </Button>
              {formData.answer_image && (
                <Box>
                  <Typography variant="subtitle2">Answer Image Preview:</Typography>
                  <img
                    src={URL.createObjectURL(formData.answer_image)}
                    alt="Answer"
                    style={{ width: '150px', marginTop: '10px' }}
                  />
                </Box>
              )}

              <TextField
                label="Additional Info"
                name="additional_info"
                value={formData.additional_info}
                onChange={handleChange}
                multiline
                rows={2}
                fullWidth
              />
              {/* Additional Info Image Upload */}
              <Button
                variant="contained"
                component="label"
              >
                Upload Additional Info Image
                <input
                  type="file"
                  name="additional_info_image"
                  accept="image/*"
                  hidden
                  onChange={handleFileChange}
                />
              </Button>
              {formData.additional_info_image && (
                <Box>
                  <Typography variant="subtitle2">Additional Info Image Preview:</Typography>
                  <img
                    src={URL.createObjectURL(formData.additional_info_image)}
                    alt="Additional Info"
                    style={{ width: '150px', marginTop: '10px' }}
                  />
                </Box>
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="secondary">Cancel</Button>
            <Button onClick={handleSubmit} color="primary">{question ? 'Update' : 'Add'}</Button>
          </DialogActions>
        </Dialog>
      );
    };

    export default EssayQuestionsReviewPage;
