// src/contexts/AuthContext.js

import React, { createContext, useState, useEffect } from 'react';

const AuthContext = createContext();

// AuthProvider to wrap around the application
export const AuthProvider = ({ children }) => {
  const [authData, setAuthData] = useState(null);

  // Check localStorage for existing auth data on mount
  useEffect(() => {
    const storedAuthData = localStorage.getItem('authData');
    if (storedAuthData) {
      setAuthData(JSON.parse(storedAuthData));
    }
  }, []);

  const login = (data) => {
    setAuthData(data);
    localStorage.setItem('authData', JSON.stringify(data));
  };

  const logout = () => {
    setAuthData(null);
    localStorage.removeItem('authData');
  };

  return (
    <AuthContext.Provider value={{ authData, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
