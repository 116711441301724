import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  CircularProgress,
  Select,
  MenuItem,
  Grid,FormControl,InputLabel
} from '@mui/material';

const LevelsUnitsManagement = () => {
  const [levels, setLevels] = useState([]);
  const [units, setUnits] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedLevelId, setSelectedLevelId] = useState(null);
  const [isLevel, setIsLevel] = useState(true); // To toggle between level and unit
  const [data, setData] = useState({
    id: '',
    title: '',
    featured_image: '',
    price: '',
    unit_type: '',
    related_subject: '',
    level_id: '',
  });

  useEffect(() => {
    fetchLevels();
  }, []);

  const fetchLevels = async () => {
    setLoading(true);
    try {
      const response = await fetch('https://mynewhomeschool.com/student/admin/api/levels.php?action=fetch_levels');
      const data = await response.json();
      setLevels(data);
    } catch (error) {
      console.error('Error fetching levels:', error);
    }
    setLoading(false);
  };

  const fetchUnitsByLevel = async (levelId) => {
    setLoading(true);
    try {
      const response = await fetch(`https://mynewhomeschool.com/student/admin/api/units.php?action=fetch_units_by_level&level_id=${levelId}`);
      const data = await response.json();
      setUnits(data);
      setSelectedLevelId(levelId);
    } catch (error) {
      console.error('Error fetching units:', error);
    }
    setLoading(false);
  };

  const handleDialogOpen = (levelOrUnit = {}, type = 'level') => {
    setIsLevel(type === 'level');
    setData({
      id: levelOrUnit.id || '',
      title: levelOrUnit.title || '',
      featured_image: type === 'level' ? levelOrUnit.featured_image : '',
      price: type === 'unit' ? levelOrUnit.price : '',
      unit_type: type === 'unit' ? levelOrUnit.unit_type : '',
      related_subject: type === 'unit' ? levelOrUnit.related_subject : '',
      level_id: type === 'unit' ? levelOrUnit.level_id : selectedLevelId || '',
    });
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setData({
      id: '',
      title: '',
      featured_image: '',
      price: '',
      unit_type: '',
      related_subject: '',
      level_id: '',
    });
    setOpenDialog(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (key === 'featured_image' && !data[key]) return;
      formData.append(key, data[key]);
    });

    const action = data.id ? 'update' : 'add';
    const url = isLevel
      ? `https://mynewhomeschool.com/student/admin/api/levels.php?action=${action}_level`
      : `https://mynewhomeschool.com/student/admin/api/units.php?action=${action}_unit`;

    try {
      const response = await fetch(url, {
        method: 'POST',
        body: formData,
      });
      const result = await response.json();
      if (result.success) {
        fetchLevels();
        if (!isLevel) {
          fetchUnitsByLevel(selectedLevelId);
        }
        handleDialogClose();
      } else {
        console.error('Error submitting data:', result.message);
      }
    } catch (error) {
      console.error('Error submitting data:', error);
    }
    setLoading(false);
  };

  const handleDelete = async (id, type) => {
    if (window.confirm(`Are you sure you want to delete this ${type}?`)) {
      const url = type === 'level'
        ? `https://mynewhomeschool.com/student/admin/api/levels.php?action=delete_level&id=${id}`
        : `https://mynewhomeschool.com/student/admin/api/units.php?action=delete_unit&id=${id}`;
      try {
        const response = await fetch(url, { method: 'GET' });
        const result = await response.json();
        if (result.success) {
          fetchLevels();
          if (type === 'unit') fetchUnitsByLevel(selectedLevelId);
        } else {
          console.error('Error deleting:', result.message);
        }
      } catch (error) {
        console.error('Error deleting:', error);
      }
    }
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Card sx={{ padding: 3 }}>
        <Typography variant="h4" gutterBottom>
          Levels and Subjects Management
        </Typography>

        <Button variant="contained" color="primary" onClick={() => handleDialogOpen({}, 'level')}>
          Add New Level
        </Button>
        <Button variant="contained" color="primary" sx={{ ml: 2 }} disabled={!selectedLevelId} onClick={() => handleDialogOpen({}, 'unit')}>
          Add New Subjects
        </Button>

        {loading ? (
          <CircularProgress sx={{ display: 'block', margin: '20px auto' }} />
        ) : (
          <Grid container spacing={3} sx={{ marginTop: 3 }}>
            <Grid item xs={4}>
              <TableContainer component={Paper}>
                <Typography variant="h6" sx={{ padding: 2 }}>Levels</Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Title</TableCell>
                      <TableCell>Featured Image</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {levels.map((level) => (
                      <TableRow key={level.id} onClick={() => fetchUnitsByLevel(level.id)} sx={{ cursor: 'pointer' }}>
                        <TableCell>{level.title}</TableCell>
                        <TableCell>
                          <img
                            src={`https://mynewhomeschool.com/student/admin/${level.featured_image}`}
                            alt="Featured"
                            width="50"
                            height="50"
                          />
                        </TableCell>
                        <TableCell>
                          <Button color="primary" onClick={() => handleDialogOpen(level, 'level')}>Edit</Button>
                          <Button color="error" onClick={() => handleDelete(level.id, 'level')}>Delete</Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Grid item xs={8}>
              <TableContainer component={Paper}>
                <Typography variant="h6" sx={{ padding: 2 }}>Subjects</Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Title</TableCell>
                      <TableCell>Price</TableCell>
                      <TableCell>Subjects Type</TableCell>
                      <TableCell>Related Subject</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {units.map((unit) => (
                      <TableRow key={unit.id}>
                        <TableCell>{unit.title}</TableCell>
                        <TableCell>{unit.price}</TableCell>
                        <TableCell>{unit.unit_type}</TableCell>
                        <TableCell>{unit.related_subject}</TableCell>
                        <TableCell>
                          <Button color="primary" onClick={() => handleDialogOpen(unit, 'unit')}>Edit</Button>
                          <Button color="error" onClick={() => handleDelete(unit.id, 'unit')}>Delete</Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        )}

        {/* Dialog for Adding/Editing Level or Unit */}
        <Dialog open={openDialog} onClose={handleDialogClose}>
          <DialogTitle>{data.id ? `Edit ${isLevel ? 'Level' : 'Subjects'}` : `Add New ${isLevel ? 'Level' : 'Subjects'}`}</DialogTitle>
          <DialogContent>
            <TextField
              label="Title"
              value={data.title}
              onChange={(e) => setData({ ...data, title: e.target.value })}
              fullWidth
              sx={{ mb: 2 }}
            />
            {isLevel ? (
              <TextField
                type="file"
                fullWidth
                sx={{ mb: 2 }}
                onChange={(e) => setData({ ...data, featured_image: e.target.files[0] })}
              />
            ) : (
              <>
                <TextField
                  label="Price"
                  value={data.price}
                  onChange={(e) => setData({ ...data, price: e.target.value })}
                  fullWidth
                  sx={{ mb: 2 }}
                />
               <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Subjects Type</InputLabel>
                <Select
                    value={data.unit_type}
                    onChange={(e) => setData({ ...data, unit_type: e.target.value })}
                    label="Subjects Type"
                >
                    <MenuItem value="general">General</MenuItem>
                    <MenuItem value="premium">Premium</MenuItem>
                </Select>
                </FormControl>


                <TextField
                  label="Related Subject"
                  value={data.related_subject}
                  onChange={(e) => setData({ ...data, related_subject: e.target.value })}
                  fullWidth
                  sx={{ mb: 2 }}
                />
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="secondary">Cancel</Button>
            <Button onClick={handleSubmit} color="primary">{loading ? <CircularProgress size={24} /> : 'Submit'}</Button>
          </DialogActions>
        </Dialog>
      </Card>
    </Box>
  );
};

export default LevelsUnitsManagement;
