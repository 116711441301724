// ReviewQuestions.js

import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Card,
    CardContent,
    IconButton,
    Pagination,
    Paper,
    InputBase,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    List,
    ListItem,
    ListItemText,
    Backdrop,
    CircularProgress,
    Tooltip,
    Snackbar,
    Alert,Divider
} from '@mui/material';
import {
    Add as AddIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
    ArrowBack as ArrowBackIcon,
    CheckCircle as CheckCircleIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import he from 'he'; // For decoding HTML entities
import DOMPurify from 'dompurify'; // For sanitizing HTML
import DangerousIcon from '@mui/icons-material/Dangerous';

const ReviewQuestions = () => {
    // Define itemsPerPage for pagination
    const itemsPerPage = 5;

    // State variables
    const [headers, setHeaders] = useState([]); // List of unique headers
    const [selectedHeader, setSelectedHeader] = useState(null); // Currently selected header
    const [questions, setQuestions] = useState([]); // Questions under the selected header
    const [searchTerm, setSearchTerm] = useState(''); // Search term for filtering questions
    const [filteredQuestions, setFilteredQuestions] = useState([]); // Filtered questions based on search
    const [page, setPage] = useState(1); // Current pagination page
    const [loading, setLoading] = useState(false); // Loading state
    const [approveConfirmOpen, setApproveConfirmOpen] = useState(false); // Confirmation dialog for approving all
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false); // Confirmation dialog for deletion
    const [questionToDelete, setQuestionToDelete] = useState(null); // Question being deleted
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' }); // Snackbar for notifications

    const navigate = useNavigate();

    // Define the base URL for images
    const BASE_IMAGE_URL = 'https://mynewhomeschool.com/student/admin/'; // Adjust based on your server's configuration

    // Fetch all unique headers with approval=0 on component mount
    useEffect(() => {
        fetchPendingHeaders();
    }, []);

    // Fetch pending headers function
    const fetchPendingHeaders = async () => {
        setLoading(true);
        try {
            const response = await fetch('https://mynewhomeschool.com/student/admin/api/review_questions.php?action=fetch_pending_headers');
            const data = await response.json();
            if (data.success) {
                setHeaders(data.headers || []);
                if ((data.headers || []).length === 0) {
                    setSnackbar({ open: true, message: 'No pending headers to review.', severity: 'info' });
                }
            } else {
                throw new Error(data.message || 'Failed to fetch headers.');
            }
        } catch (error) {
            console.error("Error fetching headers:", error);
            setSnackbar({ open: true, message: error.message, severity: 'error' });
        } finally {
            setLoading(false);
        }
    };

    // Fetch questions under a specific header
    const fetchQuestionsByHeader = async (header) => {
        setLoading(true);
        try {
            const response = await fetch(`https://mynewhomeschool.com/student/admin/api/review_questions.php?action=fetch_questions_by_header&header=${encodeURIComponent(header)}`);
            const data = await response.json();
            if (data.success) {
                setQuestions(data.questions || []);
                setFilteredQuestions(data.questions || []);
                setPage(1); // Reset to first page
                setSelectedHeader(header);
            } else {
                throw new Error(data.message || 'Failed to fetch questions.');
            }
        } catch (error) {
            console.error("Error fetching questions:", error);
            setSnackbar({ open: true, message: error.message, severity: 'error' });
        } finally {
            setLoading(false);
        }
    };

    // Handle Approve All button click
    const handleApproveAllQuestions = () => {
        if (!selectedHeader) {
            setSnackbar({ open: true, message: 'No header selected.', severity: 'warning' });
            return;
        }
        setApproveConfirmOpen(true);
    };

    // Confirm approve all
    const confirmApproveAll = async () => {
        setApproveConfirmOpen(false);
        setLoading(true);
        try {
            const response = await fetch('https://mynewhomeschool.com/student/admin/api/review_questions.php?action=approve_all_by_header', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ header: selectedHeader }),
            });
            const data = await response.json();
            if (data.success) {
                setSnackbar({ open: true, message: data.message || 'All questions approved successfully.', severity: 'success' });
                // Refresh headers and questions
                fetchPendingHeaders();
                setSelectedHeader(null);
                setQuestions([]);
                setFilteredQuestions([]);
            } else {
                throw new Error(data.message || 'Failed to approve questions.');
            }
        } catch (error) {
            console.error("Error approving questions:", error);
            setSnackbar({ open: true, message: error.message, severity: 'error' });
        } finally {
            setLoading(false);
        }
    };

    // Handle deletion of a question
    const handleDeleteQuestion = (question) => {
        setQuestionToDelete(question);
        setDeleteConfirmOpen(true);
    };

    // Confirm deletion
    const confirmDeleteQuestion = async () => {
        setDeleteConfirmOpen(false);
        if (!questionToDelete) return;
        setLoading(true);
        try {
            const response = await fetch('https://mynewhomeschool.com/student/admin/api/review_questions.php?action=delete_question', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ id: questionToDelete.id }),
            });
            const data = await response.json();
            if (data.success) {
                setSnackbar({ open: true, message: data.message || 'Question deleted successfully.', severity: 'success' });
                // Refresh questions
                fetchQuestionsByHeader(selectedHeader);
            } else {
                throw new Error(data.message || 'Failed to delete question.');
            }
        } catch (error) {
            console.error("Error deleting question:", error);
            setSnackbar({ open: true, message: error.message, severity: 'error' });
        } finally {
            setLoading(false);
            setQuestionToDelete(null);
        }
    };

    // Cancel deletion
    const handleDeleteCancel = () => {
        setDeleteConfirmOpen(false);
        setQuestionToDelete(null);
    };

    // Handle search term change
    useEffect(() => {
        if (searchTerm.trim() === '') {
            setFilteredQuestions(questions);
        } else {
            const filtered = questions.filter(q =>
                q.question_text.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredQuestions(filtered);
            setPage(1); // Reset to first page
        }
    }, [searchTerm, questions]);

    // Handle Snackbar close
    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    // Function to decode and sanitize HTML entities
    const decodeAndSanitize = (html) => {
        if (!html) return '';
        const decoded = he.decode(html);
        return DOMPurify.sanitize(decoded);
    };

    return (
        <Box sx={{ display: 'flex', height: '100vh' }}>
            {/* Backdrop for Loading Spinner */}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            {/* Sidebar */}
            <Box sx={{ width: '300px', borderRight: '1px solid #ccc', p: 2, overflowY: 'auto' }}>
                <Typography variant="h5" sx={{ mb: 2 }}>MCQ Review</Typography>
                <Typography variant="h6">Pending Headers</Typography>
                {headers.length > 0 ? (
                    <List>
                        {headers.map((header, index) => (
                            <ListItem
                                button
                                key={index}
                                onClick={() => fetchQuestionsByHeader(header)}
                                sx={{ borderBottom: '1px solid #eee' }}
                            >
                                <ListItemText
                                    primary={header}
                                    primaryTypographyProps={{ fontWeight: 'bold' }}
                                />
                                <Tooltip title="Approve All">
                                    <IconButton
                                        edge="end"
                                        color="success"
                                        onClick={(e) => {
                                            e.stopPropagation(); // Prevent triggering header click
                                            handleApproveAllQuestions();
                                        }}
                                        disabled={false} // Add conditions to disable if needed
                                    >
                                        <CheckCircleIcon />
                                    </IconButton>
                                </Tooltip>
                            </ListItem>
                        ))}
                    </List>
                ) : (
                    <Typography>No pending headers.</Typography>
                )}
            </Box>

            {/* Main Content */}
            <Box sx={{ flexGrow: 1, p: 3, overflowY: 'auto' }}>
                {selectedHeader ? (
                    <>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                            <IconButton onClick={() => setSelectedHeader(null)} color="primary">
                                <ArrowBackIcon />
                            </IconButton>
                            <Typography variant="h5" sx={{ ml: 1 }}>
                                Questions under: "{selectedHeader}"
                            </Typography>
                        </Box>

                        {/* Search Bar */}
                        <Paper
                            component="form"
                            sx={{ mb: 2, p: '2px 4px', display: 'flex', alignItems: 'center' }}
                        >
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                placeholder="Search MCQ questions"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </Paper>

                        {/* Approve All Button */}
                        <Button
                            onClick={handleApproveAllQuestions}
                            variant="contained"
                            color="success"
                            startIcon={<CheckCircleIcon />}
                            sx={{ mb: 2 }}
                        >
                            Approve All
                        </Button>

                        {/* Render Questions */}
                        {filteredQuestions.length > 0 ? (
                            <>
                                {filteredQuestions
                                    .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                                    .map((question) => (
                                        <Card key={question.id} sx={{ mb: 2, p: 2 }}>
                                            <CardContent>
                                                {/* Question Text */}
                                                <Typography
                                                    variant="h6"
                                                    dangerouslySetInnerHTML={{
                                                        __html: decodeAndSanitize(question.question_text) || 'No question text provided',
                                                    }}
                                                />
                                                

                                                {/* Question Image */}
                                                {question.question_image && (
                                                    <Box sx={{ mt: 2 }}>
                                                        <img
                                                            src={`${BASE_IMAGE_URL}${question.question_image}`}
                                                            alt="Question"
                                                            style={{ width: '100%', maxWidth: '400px' }}
                                                        />

                                                    </Box>
                                                    
                                                )}

                                                   {/* Additional Information Header and Content */}
                                                    {question.additional_info && (
                                                        <>
                                                            <Divider sx={{ mb: 3 }} />
                                                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                                                Additional Information:
                                                            </Typography>
                                                            <Typography
                                                                variant="body2"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: decodeAndSanitize(question.additional_info),
                                                                }}
                                                                sx={{ mt: 1 }}
                                                            />
                                                        </>
                                                    )}

                                                    {/* Additional Information Image */}
                                                    {question.additional_info_image && (
                                                        <>
                                                            <Box sx={{ mt: 2 }}>
                                                                <img
                                                                    src={`${BASE_IMAGE_URL}${question.additional_info_image}`}
                                                                    alt="Additional Info Image"
                                                                    style={{ width: '100%', maxWidth: '400px' }}
                                                                />
                                                            </Box>
                                                            <Divider sx={{ mt: 3 }} />
                                                        </>
                                                    )}

                                                {/* List of Answers */}
                                                <Box sx={{ mt: 2 }}>
                                                    <Typography variant="subtitle1">Answers:</Typography>
                                                    {question.answers && question.answers.length > 0 ? (
                                                        question.answers.map((answer) => (
                                                            <Box key={answer.id} sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                                                {/* Correct Answer Indicator */}
                                                                {answer.is_correct ==='1' ? (
                                                                    <CheckCircleIcon color="success" sx={{ mr: 1 }} />
                                                                ) : (
                                                                    <DangerousIcon color="danger" sx={{ mr: 1 }} />
                                                                    
                                                                )}

                                                                {/* Answer Text */}
                                                                <Typography
                                                                    variant="body1"
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: decodeAndSanitize(answer.answer_text) || 'No answer text provided',
                                                                    }}
                                                                    sx={{ flexGrow: 1 }}
                                                                />

                                                                {/* Answer Image */}
                                                                {answer.answer_image && (
                                                                    <img
                                                                        src={`${BASE_IMAGE_URL}${answer.answer_image}`}
                                                                        alt="Answer"
                                                                        style={{ width: '100px', marginLeft: '10px' }}
                                                                    />
                                                                )}
                                                            </Box>
                                                        ))
                                                    ) : (
                                                        <Typography variant="body2">No answers provided.</Typography>
                                                    )}
                                                </Box>
                                            </CardContent>
                                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                                                

                                                {/* Delete Question Button */}
                                                <Tooltip title="Delete Question">
                                                    <IconButton
                                                        color="secondary"
                                                        onClick={() => handleDeleteQuestion(question)}
                                                        aria-label="delete question"
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Card>
                                    ))}

                                {/* Pagination */}
                                <Pagination
                                    count={Math.ceil(filteredQuestions.length / itemsPerPage)}
                                    page={page}
                                    onChange={(e, value) => setPage(value)}
                                    sx={{ mt: 2 }}
                                    color="primary"
                                />
                            </>
                        ) : (
                            <Typography>No MCQ questions available under this header.</Typography>
                        )}
                    </>
                ) : (
                    <Typography variant="h6">Select a header from the sidebar to review MCQ questions.</Typography>
                )}
            </Box>

            {/* Approve All Confirmation Dialog */}
            <Dialog
                open={approveConfirmOpen}
                onClose={() => setApproveConfirmOpen(false)}
            >
                <DialogTitle>Confirm Approval</DialogTitle>
                <DialogContent>
                    <Typography>
                        Are you sure you want to approve all questions under "{selectedHeader}"?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setApproveConfirmOpen(false)} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={confirmApproveAll} color="primary">
                        Approve All
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Delete Confirmation Dialog */}
            <Dialog
                open={deleteConfirmOpen}
                onClose={handleDeleteCancel}
            >
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <Typography>
                        Are you sure you want to delete this question?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteCancel} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={confirmDeleteQuestion} color="primary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Snackbar for Notifications */}
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    )};

    export default ReviewQuestions;
