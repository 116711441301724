import React, { useState, useEffect } from 'react';
import {
  Box, Typography, Card, CardContent, IconButton, Pagination, Paper, InputBase, Button,
  Dialog, DialogTitle, DialogContent, TextField, DialogActions, CircularProgress, MenuItem
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';

const ArticlesEventsManagementPage = () => {
  const [articles, setArticles] = useState([]);
  const [events, setEvents] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [page, setPage] = useState(1);
  const [articleModalOpen, setArticleModalOpen] = useState(false);
  const [eventModalOpen, setEventModalOpen] = useState(false);
  const [articleToEdit, setArticleToEdit] = useState(null);
  const [eventToEdit, setEventToEdit] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [formError, setFormError] = useState(null);
  const itemsPerPage = 10;

  // Fetch articles and events
  useEffect(() => {
    fetchArticlesEvents();
  }, [searchTerm]);

  const fetchArticlesEvents = () => {
    setLoading(true);
    let url = `https://mynewhomeschool.com/student/admin/api/articles_events_handler.php?action=fetch&search=${searchTerm}`;
    
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setArticles(data.articles || []);
        setEvents(data.events || []);
      })
      .catch((error) => console.error("Error fetching articles and events:", error))
      .finally(() => setLoading(false));
  };

  // Filter based on search term
  useEffect(() => {
    setFilteredArticles(articles.filter(article =>
      article.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      article.content.toLowerCase().includes(searchTerm.toLowerCase())
    ));

    setFilteredEvents(events.filter(event =>
      event.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      event.description.toLowerCase().includes(searchTerm.toLowerCase())
    ));
  }, [searchTerm, articles, events]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // Handlers for Articles
  const handleAddArticle = () => {
    setArticleToEdit(null);
    setArticleModalOpen(true);
    setFormError(null);
  };

  const handleEditArticle = (article) => {
    setArticleToEdit(article);
    setArticleModalOpen(true);
    setFormError(null);
  };

  const handleDeleteArticle = (article) => {
    setItemToDelete({ ...article, type: 'article' });
    setDeleteConfirmOpen(true);
  };

  const handleArticleSubmit = (formData) => {
    const url = articleToEdit
      ? 'https://mynewhomeschool.com/student/admin/api/articles_events_handler.php?action=update_article'
      : 'https://mynewhomeschool.com/student/admin/api/articles_events_handler.php?action=add_article';

    const formDataToSend = new FormData();
    for (const key in formData) {
      if (formData[key] !== null) {
        formDataToSend.append(key, formData[key]);
      }
    }

    fetch(url, {
      method: 'POST',
      body: formDataToSend,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          setFormError(data.error);
        } else {
          fetchArticlesEvents();
          setArticleModalOpen(false);
          setFormError(null);
        }
      })
      .catch((error) => {
        console.error("Error saving article:", error);
        setFormError('An error occurred while saving the article.');
      });
  };

  // Handlers for Events
  const handleAddEvent = () => {
    setEventToEdit(null);
    setEventModalOpen(true);
    setFormError(null);
  };

  const handleEditEvent = (event) => {
    setEventToEdit(event);
    setEventModalOpen(true);
    setFormError(null);
  };

  const handleDeleteEvent = (event) => {
    setItemToDelete({ ...event, type: 'event' });
    setDeleteConfirmOpen(true);
  };

  const handleEventSubmit = (formData) => {
    const url = eventToEdit
      ? 'https://mynewhomeschool.com/student/admin/api/articles_events_handler.php?action=update_event'
      : 'https://mynewhomeschool.com/student/admin/api/articles_events_handler.php?action=add_event';

    const formDataToSend = new FormData();
    for (const key in formData) {
      if (formData[key] !== null) {
        formDataToSend.append(key, formData[key]);
      }
    }

    fetch(url, {
      method: 'POST',
      body: formDataToSend,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          setFormError(data.error);
        } else {
          fetchArticlesEvents();
          setEventModalOpen(false);
          setFormError(null);
        }
      })
      .catch((error) => {
        console.error("Error saving event:", error);
        setFormError('An error occurred while saving the event.');
      });
  };

  // Article and Event Card Components
  const ArticleCard = ({ article }) => (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        {article.featured_image && (
          <Box sx={{ mb: 2, display: 'flex', justifyContent: 'center' }}>
            <img 
              src={`https://mynewhomeschool.com/student/admin/${article.featured_image}`}
              alt={article.title}
              style={{ maxWidth: '100%', maxHeight: '150px', objectFit: 'cover' }}
            />
          </Box>
        )}
        <Typography variant="h6">{article.title}</Typography>
        <Typography>{article.content}</Typography>
      </CardContent>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1 }}>
        <IconButton color="primary" onClick={() => handleEditArticle(article)}>
          <EditIcon />
        </IconButton>
        <IconButton color="secondary" onClick={() => handleDeleteArticle(article)}>
          <DeleteIcon />
        </IconButton>
      </Box>
    </Card>
  );

  const EventCard = ({ event }) => (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        {event.featured_image && (
          <Box sx={{ mb: 2, display: 'flex', justifyContent: 'center' }}>
            <img 
              src={`https://mynewhomeschool.com/student/admin/${event.featured_image}`}
              alt={event.title}
              style={{ maxWidth: '100%', maxHeight: '150px', objectFit: 'cover' }}
            />
          </Box>
        )}
        <Typography variant="h6">{event.title}</Typography>
        <Typography>Date: {event.date_duration}</Typography>
        <Typography>Time: {event.time_duration}</Typography>
        <Typography>Address: {event.address}</Typography>
        <Typography>Presenters: {event.presenters}</Typography>
        <Typography>Professions: {event.professions}</Typography>
        <Typography>{event.description}</Typography>
      </CardContent>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1 }}>
        <IconButton color="primary" onClick={() => handleEditEvent(event)}>
          <EditIcon />
        </IconButton>
        <IconButton color="secondary" onClick={() => handleDeleteEvent(event)}>
          <DeleteIcon />
        </IconButton>
      </Box>
    </Card>
  );

  return (
    <Box sx={{ display: 'flex', p: 2 }}>
      <Box sx={{ flex: 1 }}>
        <Typography variant="h5" sx={{ mb: 2 }}>Articles & Events Management</Typography>

        <Paper component="form" sx={{ mb: 2, p: '2px 4px', display: 'flex', alignItems: 'center' }}>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search articles and events"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Paper>

        <Button onClick={handleAddArticle} variant="contained" color="primary" startIcon={<AddIcon />}>
          Add Article
        </Button>

        <Button onClick={handleAddEvent} variant="contained" color="primary" startIcon={<AddIcon />} sx={{ ml: 2 }}>
          Add Event
        </Button>

        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Typography variant="h6" sx={{ mt: 3 }}>Articles</Typography>
            {filteredArticles.slice((page - 1) * itemsPerPage, page * itemsPerPage).map((article) => (
              <ArticleCard key={article.id} article={article} />
            ))}
            
            <Typography variant="h6" sx={{ mt: 3 }}>Events</Typography>
            {filteredEvents.slice((page - 1) * itemsPerPage, page * itemsPerPage).map((event) => (
              <EventCard key={event.id} event={event} />
            ))}
          </>
        )}

        <Pagination
          count={Math.ceil((filteredArticles.length + filteredEvents.length) / itemsPerPage)}
          page={page}
          onChange={handlePageChange}
          sx={{ mt: 2 }}
        />
      </Box>

      {/* Article Modal */}
      <ArticleModal
        open={articleModalOpen}
        onClose={() => { setArticleModalOpen(false); setFormError(null); }}
        onSubmit={handleArticleSubmit}
        article={articleToEdit}
        formError={formError}
      />

      {/* Event Modal */}
      <EventModal
        open={eventModalOpen}
        onClose={() => { setEventModalOpen(false); setFormError(null); }}
        onSubmit={handleEventSubmit}
        event={eventToEdit}
        formError={formError}
      />

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this {itemToDelete?.type}?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)} color="secondary">Cancel</Button>
          {/* <Button onClick={() => confirmDelete(itemToDelete)} color="primary">Delete</Button> */}
        </DialogActions>
      </Dialog>
    </Box>
  );
};

// Define the modals for adding/editing articles and events

const ArticleModal = ({ open, onClose, onSubmit, article, formError }) => {
  const [formData, setFormData] = useState({
    id: article?.id || '',
    title: article?.title || '',
    featured_image: null,
    content: article?.content || ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({ ...formData, [name]: files[0] });  // Set the first selected file
  };

  const handleSubmit = () => {
    onSubmit(formData);  // Submit form data
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{article ? 'Edit Article' : 'Add Article'}</DialogTitle>
      <DialogContent>
        {formError && (
          <Typography color="error" variant="body2">
            {formError}
          </Typography>
        )}
        <TextField
          margin="dense"
          label="Title"
          name="title"
          value={formData.title}
          onChange={handleChange}
          fullWidth
          required
        />
        <input
          type="file"
          name="featured_image"
          onChange={handleFileChange}
          accept="image/*"
          style={{ marginTop: '10px' }}
        />
        <Typography variant="body2">Upload Featured Image</Typography>
        <TextField
          margin="dense"
          label="Content"
          name="content"
          value={formData.content}
          onChange={handleChange}
          fullWidth
          multiline
          rows={4}
          required
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">Cancel</Button>
        <Button onClick={handleSubmit} color="primary">{article ? 'Update' : 'Add'}</Button>
      </DialogActions>
    </Dialog>
  );
};

const EventModal = ({ open, onClose, onSubmit, event, formError }) => {
  const [formData, setFormData] = useState({
    id: event?.id || '',
    title: event?.title || '',
    date_duration: event?.date_duration || '',
    time_duration: event?.time_duration || '',
    address: event?.address || '',
    presenters: event?.presenters || '',
    professions: event?.professions || '',
    description: event?.description || '',
    featured_image: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({ ...formData, [name]: files[0] });  // Set the first selected file
  };

  const handleSubmit = () => {
    onSubmit(formData);  // Submit form data
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{event ? 'Edit Event' : 'Add Event'}</DialogTitle>
      <DialogContent>
        {formError && (
          <Typography color="error" variant="body2">
            {formError}
          </Typography>
        )}
        <TextField
          margin="dense"
          label="Title"
          name="title"
          value={formData.title}
          onChange={handleChange}
          fullWidth
          required
        />
        <TextField
          margin="dense"
          label="Date Duration"
          name="date_duration"
          value={formData.date_duration}
          onChange={handleChange}
          fullWidth
        />
        <TextField
          margin="dense"
          label="Time Duration"
          name="time_duration"
          value={formData.time_duration}
          onChange={handleChange}
          fullWidth
        />
        <TextField
          margin="dense"
          label="Address"
          name="address"
          value={formData.address}
          onChange={handleChange}
          fullWidth
        />
        <TextField
          margin="dense"
          label="Presenters"
          name="presenters"
          value={formData.presenters}
          onChange={handleChange}
          fullWidth
        />
        <TextField
          margin="dense"
          label="Professions"
          name="professions"
          value={formData.professions}
          onChange={handleChange}
          fullWidth
        />
        <input
          type="file"
          name="featured_image"
          onChange={handleFileChange}
          accept="image/*"
          style={{ marginTop: '10px' }}
        />
        <Typography variant="body2">Upload Featured Image</Typography>
        <TextField
          margin="dense"
          label="Description"
          name="description"
          value={formData.description}
          onChange={handleChange}
          fullWidth
          multiline
          rows={4}
          required
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">Cancel</Button>
        <Button onClick={handleSubmit} color="primary">{event ? 'Update' : 'Add'}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ArticlesEventsManagementPage;
