import React, { useState, useEffect } from 'react';
import {
  Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Select, MenuItem, Button, Checkbox, CircularProgress, Modal, TextField, IconButton
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

const AdminUserManagementPage = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [newUserData, setNewUserData] = useState({ username: '', password: '', role: 'viewer' });
  const [editUserData, setEditUserData] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);

  const tabs = [
    { name: 'dashboard', label: 'Dashboard' },
    { name: 'library', label: 'Library Management' },
    { name: 'mcq', label: 'MCQ Questions' },
    { name: 'essay', label: 'Essay Questions' },
    { name: 'students', label: 'Students Management' },
    { name: 'purchese', label: 'Purchase Management' },
    { name: 'doubt', label: 'Doubt Management' },
    { name: 'lesson', label: 'Lesson Management' },
    { name: 'events_articles', label: 'Events & Articles' },
    { name: 'admin_users', label: 'Admin Users' },
  ];

  // Fetch users on page load
// Example for fetching users
useEffect(() => {
  fetch('https://mynewhomeschool.com/student/admin/api/user_management.php?action=fetch_users')
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      if (data.success) {
        const usersWithParsedSub = data.users.map(user => ({
          ...user,
          sub: user.sub || [],
        }));
        setUsers(usersWithParsedSub);
      } else {
        setError(data.message || 'Error fetching users');
      }
      setLoading(false);
    })
    .catch(err => {
      console.error('Fetch error:', err);
      setError('Failed to fetch users');
      setLoading(false);
    });
}, []);


  const handleRoleChange = (userId, newRole) => {
    setUsers(users.map(user => (
      user.id === userId ? { ...user, role: newRole } : user
    )));
  };

  const handleTogglePermission = (userId, permission) => {
    setUsers(users.map(user => {
      if (user.id === userId) {
        const updatedSub = user.sub.includes(permission)
          ? user.sub.filter(p => p !== permission)
          : [...user.sub, permission];
        return { ...user, sub: updatedSub };
      }
      return user;
    }));
  };

  const saveChanges = (userId) => {
    const user = users.find(u => u.id === userId);
    fetch('https://mynewhomeschool.com/student/admin/api/user_management.php?action=update_permissions', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        id: userId,
        role: user.role,
        sub: user.sub,
      }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          alert('User permissions updated successfully');
        } else {
          alert('Error updating user permissions: ' + data.message);
        }
      })
      .catch(err => {
        console.error('Error updating user:', err);
        alert('Error updating user permissions');
      });
  };

  const handleDeleteUser = (userId) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      fetch('https://mynewhomeschool.com/student/admin/api/user_management.php?action=delete_user', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id: userId }),
      })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            setUsers(users.filter(user => user.id !== userId));
            alert('User deleted successfully');
          } else {
            alert('Error deleting user: ' + data.message);
          }
        })
        .catch(err => {
          console.error('Error deleting user:', err);
          alert('Error deleting user');
        });
    }
  };

  const handleOpenAddModal = () => {
    setNewUserData({ username: '', password: '', role: 'viewer' });
    setOpenAddModal(true);
  };

  const handleAddUser = () => {
    fetch('https://mynewhomeschool.com/student/admin/api/user_management.php?action=add_user', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(newUserData),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error("Failed to add user");
        }
        return response.json();
      })
      .then(data => {
        if (data.success) {
          // Add new user to the users list
          setUsers([...users, { ...newUserData, id: data.user.id, sub: [] }]);
          setOpenAddModal(false);
          alert('User added successfully');
        } else {
          alert('Error adding user: ' + data.message);
        }
      })
      .catch(err => {
        console.error('Error adding user:', err);
        alert('Error adding user');
      });
  };
  

  const handleOpenEditModal = (user) => {
    setEditUserData({ ...user });
    setOpenEditModal(true);
  };

  const handleEditUser = () => {
    fetch('https://mynewhomeschool.com/student/admin/api/user_management.php?action=edit_user', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(editUserData),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          // Update user in the users list
          setUsers(users.map(user => (user.id === editUserData.id ? editUserData : user)));
          setOpenEditModal(false);
          alert('User updated successfully');
        } else {
          alert('Error updating user: ' + data.message);
        }
      })
      .catch(err => {
        console.error('Error updating user:', err);
        alert('Error updating user');
      });
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" gutterBottom>User Management</Typography>
      <Button variant="contained" color="primary" onClick={handleOpenAddModal} sx={{ mb: 2 }}>
        Add New User
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Username</TableCell>
              <TableCell>Role</TableCell>
              {/* <TableCell>Allowed Tabs</TableCell> */}
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map(user => (
              <TableRow key={user.id}>
                <TableCell>{user.username}</TableCell>
                <TableCell>
                  <Select
                    value={user.role}
                    onChange={(e) => handleRoleChange(user.id, e.target.value)}
                  >
                    <MenuItem value="admin">Admin</MenuItem>
                    <MenuItem value="editor">Editor</MenuItem>
                    <MenuItem value="viewer">Viewer</MenuItem>
                  </Select>
                </TableCell>
                {/* <TableCell>
                  {tabs.map(tab => (
                    <Box key={tab.name}>
                      <Checkbox
                        checked={user.sub.includes(tab.name)}
                        onChange={() => handleTogglePermission(user.id, tab.name)}
                      />
                      {tab.label}
                    </Box>
                  ))}
                </TableCell> */}
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => saveChanges(user.id)}
                  >
                    Save
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleDeleteUser(user.id)}
                    sx={{ ml: 1 }}
                  >
                    Delete
                  </Button>
                  <Button
                    variant="outlined"
                    color="info"
                    onClick={() => handleOpenEditModal(user)}
                    sx={{ ml: 1 }}
                  >
                    Edit
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Add User Modal */}
      <Modal open={openAddModal} onClose={() => setOpenAddModal(false)}>
        <Box sx={{
          position: 'absolute', top: '50%', left: '50%',
          transform: 'translate(-50%, -50%)', width: 400,
          bgcolor: 'background.paper', boxShadow: 24, p: 4,
        }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6">Add New User</Typography>
            <IconButton onClick={() => setOpenAddModal(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <TextField
            label="Username"
            fullWidth
            margin="normal"
            value={newUserData.username}
            onChange={(e) => setNewUserData({ ...newUserData, username: e.target.value })}
          />
          <TextField
            label="Password"
            type="password"
            fullWidth
            margin="normal"
            value={newUserData.password}
            onChange={(e) => setNewUserData({ ...newUserData, password: e.target.value })}
          />
          <Select
            value={newUserData.role}
            onChange={(e) => setNewUserData({ ...newUserData, role: e.target.value })}
            fullWidth
            margin="normal"
          >
            <MenuItem value="admin">Admin</MenuItem>
            <MenuItem value="editor">Editor</MenuItem>
            <MenuItem value="viewer">Viewer</MenuItem>
          </Select>
          <Button variant="contained" color="primary" onClick={handleAddUser} sx={{ mt: 2 }}>
            Add User
          </Button>
        </Box>
      </Modal>

      {/* Edit User Modal */}
      <Modal open={openEditModal} onClose={() => setOpenEditModal(false)}>
        <Box sx={{
          position: 'absolute', top: '50%', left: '50%',
          transform: 'translate(-50%, -50%)', width: 400,
          bgcolor: 'background.paper', boxShadow: 24, p: 4,
        }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6">Edit User</Typography>
            <IconButton onClick={() => setOpenEditModal(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
          <TextField
            label="Username"
            fullWidth
            margin="normal"
            value={editUserData?.username || ''}
            onChange={(e) => setEditUserData({ ...editUserData, username: e.target.value })}
          />
          <TextField
            label="Password (leave blank to keep current)"
            type="password"
            fullWidth
            margin="normal"
            value={editUserData?.password || ''}
            onChange={(e) => setEditUserData({ ...editUserData, password: e.target.value })}
          />
          <Button variant="contained" color="primary" onClick={handleEditUser} sx={{ mt: 2 }}>
            Save Changes
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default AdminUserManagementPage;
