import React, { useState, useEffect } from 'react';
import {
  Box, Typography, Card, CardContent, IconButton, Pagination, Paper, InputBase, Button,
  Dialog, DialogTitle, DialogContent, TextField, DialogActions, CircularProgress, MenuItem
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, Refresh as RefreshIcon } from '@mui/icons-material';

const StudentManagementPage = () => {
  const [students, setStudents] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [page, setPage] = useState(1);
  const [studentModalOpen, setStudentModalOpen] = useState(false);
  const [studentToEdit, setStudentToEdit] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [studentToDelete, setStudentToDelete] = useState(null);
  const [levels, setLevels] = useState([]); // Levels for selection
  const [formError, setFormError] = useState(null); // Error state for the form
  const itemsPerPage = 10;

  // Fetch students and levels
  useEffect(() => {
    fetchStudents();
    fetchLevels();
  }, []);

  const fetchStudents = () => {
    setLoading(true);
    fetch(`https://mynewhomeschool.com/student/admin/api/student_handler.php?action=fetch`)
      .then((res) => res.json())
      .then((data) => {
        setStudents(data.students || []);
      })
      .catch((error) => console.error("Error fetching students:", error))
      .finally(() => setLoading(false));
  };

  const fetchLevels = () => {
    fetch(`https://mynewhomeschool.com/student/admin/api/essay_questions_handler.php?action=fetch_levels`)
      .then((res) => res.json())
      .then((data) => {
        setLevels(data.levels || []);
      })
      .catch((error) => console.error("Error fetching levels:", error));
  };

  // Filter students based on search term
  useEffect(() => {
    const filtered = students.filter(student =>
      student.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      student.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      student.mobile_number.includes(searchTerm)
    );
    setFilteredStudents(filtered);
  }, [searchTerm, students]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // Student Handlers
  const handleAddStudent = () => {
    setStudentToEdit(null);
    setStudentModalOpen(true);
    setFormError(null);
  };

  const handleEditStudent = (student) => {
    setStudentToEdit(student);
    setStudentModalOpen(true);
    setFormError(null);
  };

  const handleDeleteStudent = (student) => {
    setStudentToDelete(student);
    setDeleteConfirmOpen(true);
  };

  const confirmDeleteStudent = () => {
    fetch('https://mynewhomeschool.com/student/admin/api/student_handler.php?action=delete', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: studentToDelete.id }),
    })
    .then(() => {
      setStudents(prevStudents => prevStudents.filter(s => s.id !== studentToDelete.id));
      setDeleteConfirmOpen(false);
      setStudentToDelete(null);
    })
    .catch((error) => console.error("Error deleting student:", error));
  };

  const handleStudentSubmit = (formData) => {
    const url = studentToEdit
      ? 'https://mynewhomeschool.com/student/admin/api/student_handler.php?action=update'
      : 'https://mynewhomeschool.com/student/admin/api/student_handler.php?action=add';

    // Ensure 'approve' is either '1' or '0'
    formData.approve = formData.approve === '1' ? '1' : '0';

    fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData),
    })
    .then((res) => res.json())
    .then((data) => {
      if (data.error) {
        // Display error message
        setFormError(data.error);
      } else {
        fetchStudents();
        setStudentModalOpen(false);
        setFormError(null);
      }
    })
    .catch((error) => {
      console.error("Error saving student:", error);
      setFormError('An error occurred while saving the student.');
    });
  };

  const handleResetIP = (studentId) => {
    fetch('https://mynewhomeschool.com/student/admin/api/student_handler.php?action=reset_ip', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: studentId }),
    })
    .then(() => {
      fetchStudents();
    })
    .catch((error) => console.error("Error resetting IP address:", error));
  };

  const handleToggleApprove = (student) => {
    const newApproveStatus = student.approve === '1' ? '0' : '1';
    fetch('https://mynewhomeschool.com/student/admin/api/student_handler.php?action=toggle_approve', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: student.id, approve: newApproveStatus }),
    })
    .then(() => {
      fetchStudents();
    })
    .catch((error) => console.error("Error updating approve status:", error));
  };

  // Student Modal Component
  const StudentModal = ({ open, onClose, onSubmit, student, formError }) => {
    const [formData, setFormData] = useState({
      id: student?.id || '',
      name: student?.name || '',
      nic: student?.nic || '',
      mobile_number: student?.mobile_number || '',
      username: student?.username || '',
      email: student?.email || '',
      password: '',
      device_id: student?.device_id || '',
      device_model: student?.device_model || '',
      package_id: student?.package_id || '',
      level: student?.level || '',
      referral_code: student?.referral_code || '',
      refered_code: student?.refered_code || '',
      school: student?.school || '',
      fname: student?.fname || '',
      wts: student?.wts || '',
      dob: student?.dob || '',
      profile: student?.profile || '',
      reset_token: student?.reset_token || '',
      approve: student?.approve || '0',
    });

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = () => {
      onSubmit(formData);
    };

    return (
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>{student ? 'Edit Student' : 'Add Student'}</DialogTitle>
        <DialogContent>
          {formError && (
            <Typography color="error" variant="body2">
              {formError}
            </Typography>
          )}
          <TextField
            margin="dense"
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            fullWidth
            required
          />
          <TextField
            margin="dense"
            label="Username"
            name="username"
            value={formData.username}
            onChange={handleChange}
            fullWidth
            required
          />
          <TextField
            margin="dense"
            label="Email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            fullWidth
            required
          />
          <TextField
            margin="dense"
            label="NIC"
            name="nic"
            value={formData.nic}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Mobile Number"
            name="mobile_number"
            value={formData.mobile_number}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Password"
            name="password"
            type="password"
            value={formData.password}
            onChange={handleChange}
            fullWidth
            helperText={student ? 'Leave blank to keep current password' : ''}
            required={!student}
          />
          <TextField
            margin="dense"
            label="Device ID"
            name="device_id"
            value={formData.device_id}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Device Model"
            name="device_model"
            value={formData.device_model}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Package ID"
            name="package_id"
            value={formData.package_id}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            select
            margin="dense"
            label="Level"
            name="level"
            value={formData.level}
            onChange={handleChange}
            fullWidth
          >
            {levels.map((level) => (
              <MenuItem key={level.id} value={level.id}>
                {level.title}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            margin="dense"
            label="Referral Code"
            name="referral_code"
            value={formData.referral_code}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Referred Code"
            name="refered_code"
            value={formData.refered_code}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="School"
            name="school"
            value={formData.school}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Father's Name"
            name="fname"
            value={formData.fname}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="WhatsApp Number"
            name="wts"
            value={formData.wts}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            type="date"
            margin="dense"
            label="Date of Birth"
            name="dob"
            value={formData.dob}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
            fullWidth
          />
          {/* Additional fields can be added here */}
          <TextField
            select
            margin="dense"
            label="Approve Status"
            name="approve"
            value={formData.approve}
            onChange={handleChange}
            fullWidth
          >
            <MenuItem value="1">Active</MenuItem>
            <MenuItem value="0">In Review</MenuItem>
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">Cancel</Button>
          <Button onClick={handleSubmit} color="primary">{student ? 'Update' : 'Add'}</Button>
        </DialogActions>
      </Dialog>
    );
  };

  // Student Card Component
  const StudentCard = ({ student }) => (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        <Typography variant="h6">{student.name}</Typography>
        <Typography>Username: {student.username}</Typography>
        <Typography>Email: {student.email}</Typography>
        <Typography>Mobile: {student.mobile_number}</Typography>
        <Typography>Level: {levels.find(level => level.id === student.level)?.title || 'N/A'}</Typography>
        <Typography>Status: {student.approve === '1' ? 'Active' : 'In Review'}</Typography>
        {/* Add other fields as necessary */}
      </CardContent>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1 }}>
        <Button
          variant="contained"
          color={student.approve === '1' ? 'secondary' : 'primary'}
          onClick={() => handleToggleApprove(student)}
          sx={{ mr: 1 }}
        >
          {student.approve === '1' ? 'Disapprove' : 'Approve'}
        </Button>
        <IconButton color="primary" onClick={() => handleResetIP(student.id)}>
          <RefreshIcon />
        </IconButton>
        <IconButton color="primary" onClick={() => handleEditStudent(student)}>
          <EditIcon />
        </IconButton>
        <IconButton color="secondary" onClick={() => handleDeleteStudent(student)}>
          <DeleteIcon />
        </IconButton>
      </Box>
    </Card>
  );

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h5" sx={{ mb: 2 }}>Student Management</Typography>

      <Paper component="form" sx={{ mb: 2, p: '2px 4px', display: 'flex', alignItems: 'center' }}>
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search students"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Paper>

      <Button onClick={handleAddStudent} variant="contained" color="primary" startIcon={<AddIcon />}>
        Add Student
      </Button>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : filteredStudents.length > 0 ? (
        filteredStudents.slice((page - 1) * itemsPerPage, page * itemsPerPage).map((student) => (
          <StudentCard
            key={student.id}
            student={student}
          />
        ))
      ) : (
        <Typography>No students found</Typography>
      )}

      <Pagination
        count={Math.ceil(filteredStudents.length / itemsPerPage)}
        page={page}
        onChange={handlePageChange}
        sx={{ mt: 2 }}
      />

      {/* Student Modal */}
      <StudentModal
        open={studentModalOpen}
        onClose={() => { setStudentModalOpen(false); setFormError(null); }}
        onSubmit={handleStudentSubmit}
        student={studentToEdit}
        formError={formError}
      />

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this student?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={confirmDeleteStudent} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default StudentManagementPage;
